import { useQuery, UseQueryResult } from 'react-query';
import { getRecentGames, getUpcomingGames } from './getGames';
// import type { Game } from './types';

export interface UpcomingGamesResponse {
  id: number;
  eventName: string;
  teamName: string;
  oppositionName: string;
  startDate: string;
  venue: string;
  teamAvatar: string;
  teamPoints: number;
  oppositionTeamPoints: number;
  oppositionTeamAvatar?: string;
}
export function useUpcomingGamesQuery(
  orgId: number,
  isLeague: boolean,
  siteCode: string,
  teamId?: number,
  type?: string,
  limit?: number,
): UseQueryResult<UpcomingGamesResponse[], Error> {
  return useQuery(['upcomingGames', orgId, isLeague, siteCode, teamId], async () => {
    const response = await getUpcomingGames(orgId, isLeague, siteCode, teamId, type, limit);

    return response.result.map(game => ({
      id: game.event_id,
      eventName: game.event_name,
      teamName: game.team_name,
      oppositionName: game.event_opponent,
      startDate: game.event_start,
      venue: game.venues[0].venue_address1,
      teamAvatar: game.team_avatar,
      oppositionTeamAvatar: game.opposition_team_avatar,
    }));
  });
}

export function useRecentGamesQuery(
  orgId: number,
  isLeague: boolean,
  siteCode: string,
): UseQueryResult<UpcomingGamesResponse[], Error> {
  return useQuery(['recentGames', orgId, isLeague, siteCode], async () => {
    const response = await getRecentGames(orgId, isLeague, siteCode);

    return response.result.map(game => ({
      teamName: game.team_name,
      oppositionName: game.event_opponent,
      startDate: game.event_start,
      venue: game.venues[0].venue_address1,
      teamAvatar: game.team_avatar,
      teamPoints: game.team_points,
      oppositionTeamPoints: game.opposition_team_points,
      oppositionTeamAvatar: game.opposition_team_avatar,
    }));
  });
}
