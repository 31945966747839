import { Form, Button, Checkbox } from 'antd';
import useForm from 'antd/es/form/hooks/useForm';
import { FormData } from 'src/pages/CompetitionForm/index';
import { useState } from 'react';
import { ClubCompetition } from 'src/api/getClubCompetitions';
import PlayerFormFields from './PlayerFormFields';

export interface FormProps {
  submit: (values: FormData) => Promise<void>;
  dobRange: {
    oldestDob: string | null | undefined;
    youngestDob: string | null | undefined;
  };
  category: string | undefined;
  competition: ClubCompetition | undefined;
  loading?: boolean;
}

const DefaultForm: React.FC<FormProps> = ({ submit, dobRange, loading, category, competition }) => {
  const [form] = useForm();
  const [isOver18, setIsOver18] = useState<boolean | null>(null);

  const handleDateOfBirthChange = (value: boolean | null) => {
    setIsOver18(value);
  };

  // Handle form validation errors and scroll to the first error
  const onFinishFailed = () => {
    // Add a short delay so that DOM is updated with error classes
    setTimeout(() => {
      const errorField = document.querySelector('.ant-form-item-has-error');
      if (errorField) {
        errorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, 100);
  };

  return (
    <>
      <Form
        form={form}
        name='registration'
        onFinish={submit}
        onFinishFailed={onFinishFailed}
        scrollToFirstError={true}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
      >
        <PlayerFormFields
          dobRange={dobRange}
          category={category}
          onDateOfBirthChange={handleDateOfBirthChange}
          isOver18={isOver18}
          formTemplate={competition?.singleEntryForm}
          sportName={competition?.sport?.name || 'sports'}
        />

        {competition?.termAndConditionUrl && (
          <Form.Item
            name='termsAndConditions'
            valuePropName='checked'
            rules={[
              {
                validator: (_, value) =>
                  value ? Promise.resolve() : Promise.reject(new Error('You must accept the terms and conditions')),
              },
            ]}
            wrapperCol={{ span: 24 }}
          >
            <Checkbox>
              By checking this box, I state that I have read and understood the{' '}
              {competition?.termAndConditionUrl ? (
                <a
                  href={competition.termAndConditionUrl}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='ts-color-primary'
                >
                  terms and conditions
                </a>
              ) : (
                'terms and conditions.'
              )}
            </Checkbox>
          </Form.Item>
        )}

        <Form.Item>
          <Button style={{ border: 0 }} htmlType='submit' className='w-100 p-0' loading={loading} type='primary'>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default DefaultForm;
