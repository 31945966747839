import React, { useCallback, useMemo } from 'react';

interface PageSlice {
  start: number;
  end: number;
}

interface UsePaginationProps {
  itemsPerPage: number;
  itemCount: number;
  onPageChange?: (page: number) => void;
}

interface UsePaginationReturn {
  currentPage: number;
  pageCount: number;
  canGoNextPage: boolean;
  canGoPreviousPage: boolean;
  slice: PageSlice;
  goNextPage: () => void;
  goPreviousPage: () => void;
  setCurrentPage: (page: number) => void;
  setItemCount: (count: number) => void;
  setItemsPerPage: (count: number) => void;
  currentItemCount: number;
  currentItemsPerPage: number;
}

export function usePagination({ itemsPerPage, itemCount, onPageChange }: UsePaginationProps): UsePaginationReturn {
  const [currentPage, setCurrentPage] = React.useState(1);
  const [currentItemCount, setCurrentItemCount] = React.useState(itemCount);
  const [currentItemsPerPage, setCurrentItemsPerPage] = React.useState(itemsPerPage);

  const pageCount = useMemo(() => {
    return currentItemCount > 0 ? Math.ceil(currentItemCount / currentItemsPerPage) : 0;
  }, [currentItemCount, currentItemsPerPage]);

  const canGoNextPage = useMemo(() => currentPage < pageCount, [currentPage, pageCount]);
  const canGoPreviousPage = useMemo(() => currentPage > 1, [currentPage]);

  const slice = useMemo(() => {
    const start = (currentPage - 1) * currentItemsPerPage;
    return {
      start,
      end: start + currentItemsPerPage,
    };
  }, [currentPage, currentItemsPerPage]);

  const goNextPage = useCallback(() => {
    if (canGoNextPage) {
      const newPage = currentPage + 1;
      setCurrentPage(newPage);
      onPageChange?.(newPage);
    }
  }, [canGoNextPage, currentPage, onPageChange]);

  const goPreviousPage = useCallback(() => {
    if (canGoPreviousPage) {
      const newPage = currentPage - 1;
      setCurrentPage(newPage);
      onPageChange?.(newPage);
    }
  }, [canGoPreviousPage, currentPage, onPageChange]);

  const handleSetCurrentPage = useCallback(
    (page: number) => {
      setCurrentPage(page);
      onPageChange?.(page);
    },
    [onPageChange],
  );

  return {
    currentPage,
    pageCount,
    canGoNextPage,
    canGoPreviousPage,
    slice,
    goNextPage,
    goPreviousPage,
    setCurrentPage: handleSetCurrentPage,
    setItemCount: setCurrentItemCount,
    setItemsPerPage: setCurrentItemsPerPage,
    currentItemCount,
    currentItemsPerPage,
  };
}
