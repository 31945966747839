import { SimpleGrid } from '@chakra-ui/react';
import React from 'react';
import { HorizontalSelect } from 'src/shared/horizontalSelect/HorizontalSelect';
import { CompetitionCard } from './CompetitionCard';
import { useClub } from 'src/context/useClub';
import { ClubCompetition } from 'src/api/getClubCompetitions';

export default function Competitions() {
  const {
    club: { info, competitions },
  } = useClub();

  const beCompetitionGroupings: Record<string, ClubCompetition[]> = {};

  for (const competition of competitions.filter(competition => competition.isPublished)) {
    const competitionArea = competition.event.name ?? '';

    if (beCompetitionGroupings[competitionArea]) {
      beCompetitionGroupings[competitionArea] = [...beCompetitionGroupings[competitionArea], competition];
    }
    if (!beCompetitionGroupings[competitionArea] && !!competitionArea) {
      beCompetitionGroupings[competitionArea] = [competition];
    }
  }

  const beGroupsNames = Object.keys(beCompetitionGroupings);

  const commonTailStringArray = [];
  let breakLoop = false;
  for (let i = 0; i < 10; i++) {
    let currentWord = '';
    for (const name of beGroupsNames) {
      if (!currentWord) {
        currentWord = name.split(' ').reverse()[i];
      } else {
        if (currentWord !== name.split(' ').reverse()[i]) {
          breakLoop = true;
        }
      }
    }
    if (breakLoop) break;
    commonTailStringArray.push(currentWord);
  }

  const commonTailString = commonTailStringArray.reverse().join(' ');

  const beOptions = beGroupsNames.map(item => ({ id: item, label: item.replace(commonTailString, '') }));
  const [tab, setTab] = React.useState(beGroupsNames[0]);

  const TABS: Record<string, string> = {};

  for (const name of beGroupsNames) {
    TABS[name] = name;
  }

  return (
    competitions.length > 0 &&
    info.showCompetitions && (
      <div>
        <h3>{info.competitionsTitle || 'Our Competitions'}</h3>
        {/* {info.code === 'BE3x3' ? ( */}
        <>
          <HorizontalSelect
            className='mb-3 mt-4'
            options={beOptions}
            onSelect={({ id }) => setTab(id)}
            selectedId={tab}
          />

          <div className='mb-2'>
            <SimpleGrid columns={{ base: 1, sm: 2, md: 3, lg: 4 }} spacing={4}>
              {beCompetitionGroupings[tab].map(competition => (
                <CompetitionCard key={competition.id} competition={competition} />
              ))}
            </SimpleGrid>
          </div>
        </>
        {/* ) : (
          <>
            <div className='mb-2'>
              <SimpleGrid columns={{ base: 1, sm: 2, md: 3, lg: 4 }} spacing={4}>
                {competitions
                  .filter(competition => competition.isPublished)
                  .map(competition => (
                    <CompetitionCard key={competition.id} competition={competition} />
                  ))}
              </SimpleGrid>
            </div>
          </>
        )} */}
      </div>
    )
  );
}
