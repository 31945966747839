import { apiClient } from 'src/utils/apiClient';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function createStripeCheckoutSession(email: string, productName: string, productCost: number, productQuantity: number, client_reference_id: string, orgId: number, clubCode: string): Promise<any> {
  const response = await apiClient<Response>(`/v1/stripe/session/create/${orgId}`, {
    method: 'POST',
    body: JSON.stringify({
        email,
        client_reference_id,
        amount: productQuantity,
        product_name: productName,
        price: productCost,
        success_url: `${window.location.origin}/thankyou?code=${clubCode}`,
    })
  });

  return response;
}
