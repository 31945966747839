import { useQuery, useQueries } from 'react-query';
import { apiClient } from 'src/utils/apiClient';
import { getUpcomingGames } from 'src/pages/home/games/queries/getGames';

export interface ClubTeamsResponse {
  error: boolean;
  code: number;
  message: string;
  teams: {
    data: {
      team_id: number;
      team_name: string;
      team_description: string | null;
      team_avatar: string | null;
      team_code: string;
      team_division: string | null;
      coach1: number | null;
      team_coach1_role: string | null;
      coach2: number | null;
      team_coach2_role: string | null;
      coach3: number | null;
      team_coach3_role: string | null;
      team_tags: string | null;
      created_by: number;
      created_at: string;
      is_competition: string | null;
      league_id: number | null;
      team_uk_basketball_be_league: string | null;
      team_uk_basketball_be_div_stage: string | null;
      org_name: string;
      org_id: number;
      player_count: number | null;
      org_site_code: string;
    }[];
  };
}

const getClubTeams = async (siteCode?: string) => {
  // {{baseUrl}}/{{version}}/website/teams?site_code=JmUFih
  const response = await apiClient<ClubTeamsResponse>(`/v1/website/teams`, { siteCode });

  return response;
};

export const useGetClubTeamsQuery = (siteCode?: string) => {
  // First query to get all teams
  const teamsQuery = useQuery(
    `team-coaches-query-${siteCode}`,
    async () => {
      const response = await getClubTeams(siteCode || '');
      return response.teams.data.map(team => ({
        id: team.team_id,
        teamName: team.team_name,
        teamAvatar: team.team_avatar,
        orgId: team.org_id,
        orgSiteCode: team.org_site_code,
        leagueId: team.league_id,
        teamCode: team.team_code,
      }));
    },
    {
      enabled: !!siteCode,
    },
  );

  // Parallel queries for upcoming games for each team
  const upcomingGamesQueries = useQueries(
    teamsQuery.data?.map(team => ({
      queryKey: ['upcoming-games', team.id],
      queryFn: async () => {
        const response = await getUpcomingGames(team.orgId, false, team.orgSiteCode, team.id, 'practice', 1);
        return response.result[0];
      },
      enabled: !!teamsQuery.data,
    })) ?? [],
  );

  // Combine the results
  const isLoading = teamsQuery.isLoading || upcomingGamesQueries.some(query => query.isLoading);
  const isError = teamsQuery.isError || upcomingGamesQueries.some(query => query.isError);
  const error = teamsQuery.error || upcomingGamesQueries.find(query => query.error)?.error;

  const data = teamsQuery.data?.map((team, index) => {
    const nextGame = upcomingGamesQueries[index]?.data;
    return {
      ...team,
      nextPractice: nextGame
        ? {
            name: nextGame.event_name,
            time: nextGame.event_start,
          }
        : null,
    };
  });

  return {
    data,
    isLoading,
    isError,
    error,
  };
};
