import { apiClient } from 'src/utils/apiClient';

interface UpcomingGame {
  event_id: number;
  team_id: number;
  event_type: string;
  event_name: string;
  event_description: string;
  event_start: string;
  event_end: string;
  event_recurring: number;
  event_recurring_info: null;
  event_color: null;
  event_venue_placename: string;
  event_venue_lat: string;
  event_venue_lng: string;
  event_venue_gm_zoom: number;
  created_by: number;
  deleted_at: null;
  deleted_by: null;
  created_at: string;
  updated_at: string;
  event_venue_lat_delta: string;
  event_venue_lng_delta: string;
  event_door_number: null;
  event_address1: string;
  event_address2: null;
  event_city: string;
  event_postcode: string;
  event_state_province: null;
  event_country: null;
  event_country_code: null;
  event_opponent: string;
  event_northeast_lat: string;
  event_northeast_lng: string;
  event_southwest_lat: string;
  event_southwest_lng: string;
  parent_id: null;
  league_flag: number;
  event_recurring_until_date: null;
  flag_player_of_the_match: number;
  flag_remind_player_to_respond: number;
  flag_remind_coach_of_game_attendance: number;
  event_oponent_manager_name: null;
  event_oponent_manager_phone: null;
  event_oponent_manager_email: null;
  event_oponent_colour: null;
  event_season: null;
  event_competition: null;
  event_division_stage: null;
  event_is_at_home: number;
  event_referee_user_id1: number;
  event_referee_user_id2: number;
  event_referee_user_id3: null;
  event_confirmation_status_flag: number;
  team_name: string;
  team_avatar: string;
  yes_attendance_count: string;
  no_attendance_count: string;
  without_attendance_count: string;
  venues: Venue[];
  team_points: number | null;
  opposition_team_points: number | null;
  opposition_team_avatar?: string;
}

interface Venue {
  venue_id: number;
  venue_name: string;
  venue_address1: string;
  venue_city: string;
  venue_postcode: string;
  venue_country: null;
  venue_country_code: null;
  venue_full_address: string;
  venue_provider: string;
  venue_lat: string;
  venue_lng: string;
  pivot: {
    event_id: number;
    venue_id: number;
  };
}

interface GetUpcomingGamesResponse {
  code: number;
  error: boolean;
  message: string;
  result: UpcomingGame[];
}

export async function getUpcomingGames(
  orgId: number,
  isLeague: boolean,
  siteCode: string,
  teamId?: number,
  type?: string,
  limit?: number,
): Promise<GetUpcomingGamesResponse> {
  const filters: string[] = [];
  filters.push(`org_id=${orgId}`);
  filters.push(`league_filter=${isLeague ? '1' : '0'}`);
  filters.push(`limit=${limit || 3}`);
  filters.push(`type=game`);
  if (teamId) {
    filters.push(`team_id=${teamId}`);
  }
  if (type) {
    filters.push(`type=${type}`);
  }

  const queryString = filters.length > 0 ? `?${filters.join('&')}` : '';

  return apiClient(`/v1/website/events/upcoming${queryString}`, { siteCode });
}

export async function getRecentGames(
  orgId: number,
  isLeague: boolean,
  siteCode: string,
): Promise<GetUpcomingGamesResponse> {
  const filters: string[] = [];
  filters.push(`org_id=${orgId}`);
  filters.push(`league_filter=${isLeague ? '1' : '0'}`);
  filters.push(`limit=3`);
  filters.push(`type=game`);

  const queryString = filters.length > 0 ? `?${filters.join('&')}` : '';

  return apiClient(`/v1/website/events/previous${queryString}`, { siteCode });
}
