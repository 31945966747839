import { toZonedTime } from 'date-fns-tz';
import { useEffect, useState } from 'react';
import { useClub } from 'src/context/useClub';
import { apiClient } from 'src/utils/apiClient';

export interface TeamEvent {
  id: number;
  date: Date;
  venue: string;
  result: string;
  opponent: string;
  venueIsHome: boolean;
  leagueFlag: number;
  eventConfirmationStatus: number;
  teamPoints: number;
  oppositionTeamPoints: number;
  teamName: string;
}

interface ClubTeamsResponse {
  results: {
    created_at: DateTimeString;
    created_by: number;
    event_address1: string;
    event_address2: string | null;
    event_city: string;
    event_coach_1: number | null;
    event_coach_2: number | null;
    event_color: string | null;
    event_competition: string | null;
    event_country: string | null;
    event_country_code: string | null;
    event_description: string;
    event_division_stage: string | null;
    event_door_number: string | null;
    event_end: DateTimeString;
    event_id: number;
    event_is_at_home: 1 | 0;
    event_name: string;
    event_oponent_colour: string | null;
    event_oponent_manager_email: string | null;
    event_oponent_manager_name: string | null;
    event_oponent_manager_phone: string | null;
    event_opponent: string | null;
    event_postcode: string;
    event_recurring: 1 | 0;
    event_recurring_info: string | null;
    event_recurring_until_date: DateTimeString | null;
    event_season: string | null;
    event_start: DateTimeString;
    event_state_province: string | null;
    event_type: string;
    event_venue_gm_zoom: number;
    event_venue_lat: string | null;
    event_venue_lng: string | null;
    event_venue_placename: string;
    flag_player_of_the_match: 1 | 0;
    flag_remind_coach_of_game_attendance: 1 | 0;
    flag_remind_player_to_respond: 1 | 0;
    parent_id: number | null;
    team_id: number;
    team_name: string;
    updated_at: DateTimeString;
    league_flag: number;
    event_confirmation_status_flag: number;
    team_points: number | null;
    opposition_team_points: number | null;
    venues: {
      venue_id: number;
      venue_name: string | null;
      venue_address1: string;
      venue_city: string;
      venue_postcode: string;
      venue_country: string | null;
      venue_country_code: string | null;
      venue_full_address: string;
      venue_provider: string | null;
      venue_lat: string;
      venue_lng: string;
      pivot: {
        event_id: number;
        venue_id: number;
      };
    }[];
  }[];
}

export type TeamFixture = TeamEvent[];

export interface TeamFixturesParams {
  siteCode: string;
  teamId?: number;
  season?: number;
  division?: number;
  fromDate?: string;
  toDate?: string;
}

export async function getTeamFixtures({
  teamId,
  siteCode,
  season,
  division,
  fromDate,
  toDate,
}: TeamFixturesParams): Promise<TeamFixture> {
  const params: string[] = [];
  if (teamId) params.push(`team_id=${teamId}`);
  if (season) params.push(`season=${season}`);
  if (division) params.push(`division=${division}`);
  if (fromDate) params.push(`from_date=${fromDate}`);
  if (toDate) params.push(`to_date=${toDate}`);

  const queryString = params.length > 0 ? `?${params.join('&')}` : '';

  const { results } = await apiClient<ClubTeamsResponse>(`/v1/website/calendar${queryString}`, { siteCode });

  return results.map<TeamEvent>(event => ({
    date: toZonedTime(event.event_start, 'UTC'),
    id: event.event_id,
    opponent: event.event_opponent || 'Unknown',
    result: 'Win',
    // venue: stringFromJoinedParts([event.event_address1, event.event_city || '', event.event_postcode]),
    venue: event?.venues[0]?.venue_address1 || '-',
    venueIsHome: Boolean(event.event_is_at_home),
    leagueFlag: event.league_flag,
    eventConfirmationStatus: event.event_confirmation_status_flag,
    teamPoints: event.team_points ?? 0,
    oppositionTeamPoints: event.opposition_team_points ?? 0,
    teamName: event.team_name,
  }));
}

export interface UseTeamFixturesParams {
  teamId: number;
  season?: number;
  division?: number;
  fromDate?: string;
  toDate?: string;
}

export function useTeamFixtures({ teamId, season, division, fromDate, toDate }: UseTeamFixturesParams) {
  const { club } = useClub();

  const [fixtures, setFixtures] = useState<TeamFixture | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchFixtures = async () => {
      try {
        const result = await getTeamFixtures({
          teamId,
          siteCode: club.info.code,
          season,
          division,
          fromDate,
          toDate,
        });
        setFixtures(result);
      } catch (err) {
        setFixtures(null);
        setError(err as Error);
      }
      setIsLoading(false);
    };
    fetchFixtures();
  }, [club.info.code, teamId, season, division, fromDate, toDate]);

  return { fixtures, isLoading, error };
}
