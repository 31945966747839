import React from 'react';
import { ChevronLeft, ChevronRight } from 'react-feather';
import css from './Pagination.module.scss';
import { usePagination } from './usePagination';
import { cx } from 'src/utils/styles';

interface PaginationProps {
  className?: string;
  model: ReturnType<typeof usePagination>;
}

export const Pagination: React.FC<PaginationProps> = ({ className, model }) => {
  const {
    currentPage,
    pageCount,
    canGoNextPage,
    canGoPreviousPage,
    goNextPage,
    goPreviousPage,
    slice,
    currentItemCount,
    currentItemsPerPage,
  } = model;

  const entryEndCount = canGoNextPage ? currentPage * slice.end : currentItemCount;
  const entryStartCount = canGoPreviousPage ? entryEndCount - currentItemsPerPage + 1 : 1;

  return (
    <div className={`d-flex justify-content-between align-items-center ${className || ''}`}>
      <p className='text-center ts-fs-xs m-0 ms-2 ts-fs-14 fw-bold text-uppercase'>
        {entryStartCount} - {entryEndCount} OF {currentItemCount}
      </p>
      <div className='d-flex align-items-center justify-content-center'>
        <button
          className={cx('p-0 ms-0 me-3 border-0 d-flex align-items-center justify-content-center', css.button)}
          disabled={!canGoPreviousPage}
          title='Previous Page'
          type='button'
          onClick={goPreviousPage}
        >
          <ChevronLeft />
        </button>
        <div className='ts-fs-14 fw-bold text-uppercase'>
          {currentPage} OF {pageCount}
        </div>
        <button
          className={cx('p-0 ms-3 border-0 d-flex align-items-center justify-content-center', css.button)}
          disabled={!canGoNextPage}
          title='Next Page'
          type='button'
          onClick={goNextPage}
        >
          <ChevronRight />
        </button>
      </div>
    </div>
  );
};
