import { useQuery, UseQueryResult } from 'react-query';
import { apiClient } from 'src/utils/apiClient';

interface RefereesResponse {
  data: {
    created_at: string;
    deleted_at: string | null;
    direct_registration_flag: number;
    email: string;
    last_seen: string;
    login_counter: number;
    org_id: number | null;
    profile_avatar: string | null;
    profile_first_name: string;
    profile_id: number;
    profile_last_name: string;
    profile_number: string | null;
    profile_position: string | null;
    profile_reg_file: string | null;
    profile_reg_number: string | null;
    profile_role: string;
    profile_telephone1: string;
    profile_telephone2: string | null;
    updated_at: string;
    user_id: number;
  }[];
  current_page: number;
  first_page_url: string;
  from: number;
  last_page: number;
  last_page_url: string;
  links: { active: boolean; label: string; url: null | string }[];
  next_page_url: string;
  path: string;
  per_page: number;
  prev_page_url: null | string;
  to: number;
  total: number;
}

export interface RefereesData {
  id: number;
  name: string;
  avatar: string;
  licenseNumber: string;
  createdAt: string;
  email: string;
  phone: string;
}

interface RefereesQueryResponse {
  referees: RefereesData[];
  totalCount: number;
}

interface RefereesQueryParams {
  page: number;
  itemsPerPage: number;
}

async function getReferees(siteCode: string, params: RefereesQueryParams): Promise<RefereesResponse> {
  const response = await apiClient<RefereesResponse>(
    `/v1/website/users/referees?page=${params.page}&itemsPerPage=${params.itemsPerPage}`,
    { siteCode },
  );
  return response;
}

export function useRefereesQuery(
  siteCode: string,
  params: RefereesQueryParams,
): UseQueryResult<RefereesQueryResponse, Error> {
  return useQuery(
    ['referees', siteCode, params.page, params.itemsPerPage],
    async () => {
      const referees = await getReferees(siteCode, params);

      return {
        referees: referees.data.map(referee => ({
          id: referee.user_id,
          name: `${referee.profile_first_name} ${referee.profile_last_name}`,
          avatar: referee.profile_avatar,
          licenseNumber: referee.profile_number,
          createdAt: referee.created_at,
          email: referee.email,
          phone: referee.profile_telephone1,
        })),
        totalCount: referees.total,
      };
    },
    {
      staleTime: 1000 * 60 * 60 * 24,
    },
  );
}
