import { apiClient } from 'src/utils/apiClient';

export interface TeamStaffMember {
  name: string;
  id: number;
  avatarUrl: string | null;
  teamId: number;
  teamName: string;
  email: string;
}

export interface ClubTeam {
  id: number;
  name: string;
  logoUrl: string | null;
  code: string;
  staff: [TeamStaffMember | null, TeamStaffMember | null];
  division: string | null;
  isLeagueTeam: boolean;
  orgSiteCode: string;
  orgId: number;
}

export type ClubTeams = ClubTeam[];

interface ClubTeamsResponse {
  teams: {
    data: {
      coach1: number | null;
      coach2: number | null;
      created_at: DateTimeString;
      created_by: number;
      created_first_name: string;
      created_last_name: string;
      org_id: number;
      org_name: string;
      player_count: number;
      team_avatar: string | null;
      team_code: string;
      team_description: string;
      team_id: number;
      team_name: string;
      team_tags: string | null;
      team_division: string | null;
      league_id: number | null;
      org_site_code: string;
    }[];
  };
}

export async function getClubTeams(siteCode: string): Promise<ClubTeams> {
  const {
    teams: { data },
  } = await apiClient<ClubTeamsResponse>(`/v1/website/teams`, { siteCode });

  return data.map(team => ({
    code: team.team_code,
    id: team.team_id,
    logoUrl: team.team_avatar,
    name: team.team_name,
    staff: [null, null],
    division: team.team_division,
    isLeagueTeam: team.league_id !== null,
    orgSiteCode: team.org_site_code,
    orgId: team.org_id,
  }));
}
