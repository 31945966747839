export function getNonRefValue<T>(value: T): T;
export function getNonRefValue<T extends Array<unknown> | Date | File | Record<string, unknown>>(value: T): T {
  if (Array.isArray(value)) {
    return [...value] as T;
  }
  if (value instanceof Date) {
    return new Date(value) as T;
  }
  if (value instanceof File) {
    return value;
  }
  if (typeof value === 'object' && value !== null) {
    return { ...value };
  }
  return value;
}

export function dateIsValid(date?: Date | null | string): date is Date {
  return date !== null && date !== undefined && !Number.isNaN(new Date(date).getTime());
}

export function dateFromString(date: undefined | null | string | Date): Date | null {
  if (dateIsValid(date)) {
    const newDate = new Date(date);
    return newDate;
  }
  return null;
}

/**
 * Prefix a number with zeros until it reaches the specified target digit length.
 * @note If the number has more digits than the target digit length, then the number is returned as-is.
 * @example For 'targetDigitLength = 3', number '7' is returned '007'.
 */
export function numberWithZeroPadding(n: number, targetDigitLength: number): string {
  const numberDigitLength = n.toString().length;
  if (numberDigitLength >= targetDigitLength) {
    return String(n);
  }

  return (
    Array.from({ length: targetDigitLength - numberDigitLength })
      .map(() => '0')
      .join('') + String(n)
  );
}

/**
 * Returns stringified date without timezone offset; e.g. '2021-09-17 09:13:57' instead of '2021-09-17T09:13:57.645Z'
 */
export function dateToISONoTimezone(date: Date, includeTime?: boolean): string {
  const timeString = includeTime
    ? `${numberWithZeroPadding(date.getUTCHours(), 2)}:${numberWithZeroPadding(
        date.getUTCMinutes(),
        2,
      )}:${numberWithZeroPadding(date.getUTCSeconds(), 2)}`
    : '';
  return `${numberWithZeroPadding(date.getUTCFullYear(), 2)}-${numberWithZeroPadding(
    date.getUTCMonth() + 1,
    2,
  )}-${numberWithZeroPadding(date.getUTCDate(), 2)} ${timeString}`.trim();
}

export function formatTime(date: Date): string {
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${hours}:${minutes}`;
}

export interface StartEndDates {
  endDate: Date;
  startDate: Date;
}

export function getMonthDates(date = new Date()): StartEndDates {
  return {
    endDate: new Date(date.getFullYear(), date.getMonth() + 1, 0),
    startDate: new Date(date.getFullYear(), date.getMonth(), 1),
  };
}

export function getWeekDates(date = new Date()): StartEndDates {
  const dayOfWeek = date.getDay() || 7;
  const weekStart = new Date(date);
  weekStart.setHours(-24 * (dayOfWeek - 1));

  const startDate = new Date(weekStart.getFullYear(), weekStart.getMonth(), weekStart.getDate());
  const endDate = new Date(startDate);
  endDate.setHours(24 * 6);

  return { endDate, startDate };
}

export function getYearDates(date = new Date()): StartEndDates {
  return {
    endDate: new Date(date.getFullYear(), 12, 0, 0, 0, 0, 0),
    startDate: new Date(date.getFullYear(), 0, 1, 0, 0, 0, 0),
  };
}

export function getTodayDates(): StartEndDates {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return {
    endDate: today,
    startDate: today,
  };
}

export function getAllTimeDates(): StartEndDates {
  // this is to prevent expiring the querys cache
  const date = new Date();
  const today = new Date(date.getFullYear(), date.getMonth(), date.getDate());
  return {
    endDate: today,
    startDate: new Date('01/01/2010'),
  };
}

export function datesOfPreviousPeriod(startDate: Date, endDate: Date): { startDate: Date; endDate: Date } {
  const prevEndDate = new Date(startDate);
  prevEndDate.setHours(-24);

  return {
    endDate: prevEndDate,
    startDate: new Date(prevEndDate.getTime() - (endDate.getTime() - startDate.getTime())),
  };
}

export enum PERIOD_OPTIONS {
  thisSeason = 'thisSeason',
  lastSeason = 'lastSeason',
  last30 = 'last30',
  last90 = 'last90',
  thisYear = 'thisYear',
  lastYear = 'lastYear',
}

export const readableDatePeriods = new Map<PERIOD_OPTIONS, string>([
  [PERIOD_OPTIONS.thisSeason, 'This season'],
  [PERIOD_OPTIONS.lastSeason, 'Last season'],
  [PERIOD_OPTIONS.thisYear, 'This year'],
  [PERIOD_OPTIONS.lastYear, 'Last year'],
  [PERIOD_OPTIONS.last30, 'Last 30 days'],
  [PERIOD_OPTIONS.last90, 'Last 90 days'],
]);

export const periodOptions = Array.from(readableDatePeriods, ([key, value]) => ({ id: key, label: value }));
Object.freeze(periodOptions);

const seasonStartMonth = 4;
const seasonEndMonth = 3;

const getSeasonStart = (year: number) => new Date(year, seasonStartMonth, 1, 2, 0, 0, 0);

const getSeasonEnd = (year: number) => {
  const newEndDate = new Date(year, seasonEndMonth, 30, 2, 0, 0, 0);
  return newEndDate;
};

export function startEndDatesFromPeriod(period: PERIOD_OPTIONS): StartEndDates {
  const today = new Date();
  today.setUTCHours(2, 0, 0, 0);

  let dates: StartEndDates = { endDate: today, startDate: today };
  let thisYear = today.getUTCFullYear();
  const currentYearSeasonEnd = new Date(thisYear, seasonEndMonth, 0, 2, 0, 0, 0);
  const isSeasonPeriod = period === PERIOD_OPTIONS.thisSeason || period === PERIOD_OPTIONS.lastSeason;

  if (isSeasonPeriod && today > currentYearSeasonEnd) {
    thisYear++;
    today.setUTCFullYear(thisYear);
  }
  const lastYear = thisYear - 1;

  switch (period) {
    case PERIOD_OPTIONS.last30:
      dates.startDate = new Date(getNonRefValue(today).setDate(today.getDate() - 30));
      break;
    case PERIOD_OPTIONS.last90:
      dates.startDate = new Date(getNonRefValue(today).setDate(today.getDate() - 90));
      break;
    case PERIOD_OPTIONS.thisSeason:
      dates = { startDate: getSeasonStart(lastYear), endDate: getSeasonEnd(thisYear) };
      break;
    case PERIOD_OPTIONS.thisYear:
      dates = getYearDates();
      break;
    case PERIOD_OPTIONS.lastSeason:
      dates = { startDate: getSeasonStart(lastYear - 1), endDate: getSeasonEnd(lastYear) };
      break;
    case PERIOD_OPTIONS.lastYear:
      dates = getYearDates(new Date(getNonRefValue(today).setFullYear(lastYear)));
      break;
  }
  return dates;
}

export function getCurrentSeasonYear(): number {
  const today = new Date();
  const currentMonth = today.getMonth();
  const currentYear = today.getFullYear();
  if (currentMonth < 8) {
    return currentYear - 1;
  }
  return currentYear;
}

/**
 * Formats a date string to the format 'Wed 05 Mar 2025 at 20:00'
 * @param dateString Date string in ISO format (e.g. '2025-03-14T20:00:00.000000Z')
 * @returns Formatted date string or '--' if the date is invalid
 */
export function formatReadableDateAndTime(dateString: string | undefined | null): string {
  if (!dateString) return '--';

  try {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return '--';

    // Get day of week (e.g., 'Wed')
    const dayOfWeek = date.toLocaleDateString('en-US', { weekday: 'short' });

    // Get day of month with leading zero (e.g., '05')
    const dayOfMonth = numberWithZeroPadding(date.getDate(), 2);

    // Get month abbreviation (e.g., 'Mar')
    const month = date.toLocaleDateString('en-US', { month: 'short' });

    // Get year (e.g., '2025')
    const year = date.getFullYear();

    // Get hours and minutes with leading zeros (e.g., '20:00')
    const hours = numberWithZeroPadding(date.getHours(), 2);
    const minutes = numberWithZeroPadding(date.getMinutes(), 2);

    return `${dayOfWeek} ${dayOfMonth} ${month} ${year} at ${hours}:${minutes}`;
  } catch (error) {
    return '--';
  }
}
