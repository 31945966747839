import { useUpcomingGamesQuery } from './queries/gamesQuery';
import { useClub } from 'src/context/useClub';
import { Card } from 'src/shared/widgets/Card';
import { format, parseISO } from 'date-fns';
import { PrimaryInvertedButton } from 'src/shared/buttons';
import { useNavigate, useParams } from 'react-router-dom';

export default function UpcomingMatches() {
  const {
    club: { info },
  } = useClub();

  const navigate = useNavigate();
  const { teamId } = useParams<{ teamId: string }>();

  const upcomingGamesQuery = useUpcomingGamesQuery(
    info.orgId,
    info.leagueFlag,
    info.code,
    teamId ? Number(teamId) : undefined,
  );

  return (
    <div className='container-fluid container-lg'>
      <h3 className='mb-3'>Upcoming Matches</h3>

      {upcomingGamesQuery.data && upcomingGamesQuery.data.length > 0 ? (
        <div className='row'>
          {upcomingGamesQuery.data?.map(game => (
            <div className='col-12 col-md-4 mb-3'>
              <Card className='p-5'>
                <div className='d-flex justify-content-between align-items-center gap-3 mb-3'>
                  <div className='d-flex flex-column align-items-center justify-content-center flex-grow-1 text-center gap-3'>
                    <img
                      src={game.teamAvatar}
                      className='rounded-circle object-fit-cover'
                      style={{ width: '70px', height: '70px' }}
                    />
                    <p className='ts-fs-15 ts-fw-500'>
                      {game.teamName.length > 15 ? `${game.teamName.slice(0, 15)}...` : game.teamName}
                    </p>
                  </div>
                  <p>-</p>
                  <div className='d-flex flex-column align-items-center justify-content-center flex-grow-1 text-center gap-3'>
                    <img
                      src={game.oppositionTeamAvatar || '/assets/img/shield_place_holder.png'}
                      className='rounded-circle object-fit-cover'
                      style={{ width: '70px', height: '70px' }}
                    />
                    <p className='ts-fs-15 ts-fw-500'>
                      {game.oppositionName.length > 15 ? `${game.oppositionName.slice(0, 15)}...` : game.oppositionName}
                    </p>
                  </div>
                </div>
                <h5 className='fs-6 fw-bold mb-1'>
                  {format(parseISO(game.startDate.replace('Z', '')), "EEE 'at' HH:mm")}
                </h5>
                <p className='ts-fs-14 ts-fw-500 ts-text-overflow'>{game.venue}</p>
              </Card>
            </div>
          ))}
        </div>
      ) : (
        <p className='ts-fs-16'>No upcoming matches found</p>
      )}

      <div className='text-end mt-2'>
        <PrimaryInvertedButton className='ts-fw-500' onClick={() => navigate('/calendar')}>
          View calendar
        </PrimaryInvertedButton>
      </div>
    </div>
  );
}
