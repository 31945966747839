import { useClub } from 'src/context/useClub';
import DOMPurify from 'dompurify';
import css from './index.module.scss';
import cx from 'classnames';
import React from 'react';
import { Card } from 'src/shared/widgets/Card';
import { useContactsQuery } from './queries/getContactsQuery';
import { useRefereesQuery } from './queries/getRefereesQuery';
import { usePagination } from 'src/shared/widgets/Pagination/usePagination';
import { Pagination } from 'src/shared/widgets/Pagination/Pagination';
import { DataTable, DataTableColumnDef } from 'src/shared/widgets/DataTable/DataTable';
import { RoundedImage } from 'src/shared/widgets/RoundedImage';
import { formatDistanceToNow } from 'date-fns';
import type { RefereesData } from './queries/getRefereesQuery';
import { CSS_BREAKPOINTS } from 'src/constants';
import { DataTableItemDialog } from 'src/shared/widgets/DataTable/DataTableItemDialog';
import { QueryLoader } from 'src/shared/utils/QueryLoader';

export const LeadershipCard = ({
  name,
  jobTitle,
  avatar,
  email,
}: {
  name: string;
  jobTitle: string;
  avatar: string;
  email?: string;
}) => (
  <Card>
    <div className='d-flex align-items-center'>
      <img
        src={avatar || `https://ui-avatars.com/api/?name=${name}`}
        alt={name}
        style={{
          width: '60px',
          height: '60px',
          borderRadius: '50%',
          marginRight: '15px',
        }}
      />
      <div>
        <p className='mb-1 ts-fs-18'>{jobTitle}</p>
        <h5 className='mb-1 ts-fw-700 ts-fs-18'>{name}</h5>
        {email && (
          <a href={`mailto:${email}`} className='mb-0 ts-fs-14'>
            {email}
          </a>
        )}
      </div>
    </div>
  </Card>
);

interface ResumeItemProps {
  title: string;
  value: number;
  withDivider?: boolean;
}

const ResumeItem: React.FC<ResumeItemProps> = ({ title, value, withDivider = true }) => (
  <>
    <div className='d-flex flex-column align-items-center justify-content-center'>
      <p className='ts-fw-500 fs-4 mb-1'>{value}</p>
      <p className='text-center fs-6' style={{ whiteSpace: 'nowrap' }}>
        {title}
      </p>
    </div>
    {withDivider && (
      <div
        style={{
          width: '1px',
          height: '3.5rem',
          backgroundColor: '#bbc3d3',
          margin: '0 20px',
        }}
      />
    )}
  </>
);

const SEASON_STATS = [
  { title: 'D1 teams', value: 9 },
  { title: 'D2 teams', value: 10 },
  { title: 'Referees', value: 50 },
  { title: 'Fixtures', value: 450 },
  { title: 'Ref bookings', value: 90 },
  { title: 'D1 players', value: 60 },
  { title: 'D2 players', value: 80 },
  { title: 'Coaches', value: 30 },
];

const JOB_TITLE_ORDER = [
  'Chair',
  'Treasurer',
  'Safeguarding and Welfare Officer',
  'Club Liaison',
  'Table Officials Mentor',
  'Division 1 Secretary',
  'Division 2 Secretary',
  'Disciplinary Secretary',
  'Constitution and Policies',
  'Referees Mentor',
];

export default function AboutPage() {
  const {
    club: { info },
  } = useClub();

  const contactsQuery = useContactsQuery(info.code);

  const pagination = usePagination({
    itemsPerPage: 20,
    itemCount: 0,
    onPageChange: () => {},
  });

  const refereesQuery = useRefereesQuery(info.code, {
    page: pagination.currentPage,
    itemsPerPage: pagination.currentItemsPerPage,
  });

  // Update total count when we get the response
  React.useEffect(() => {
    if (refereesQuery.data?.totalCount) {
      pagination.setItemCount(refereesQuery.data.totalCount);
    }
  }, [refereesQuery.data?.totalCount]);

  const columns: DataTableColumnDef<RefereesData>[] = [
    {
      key: 'avatar',
      title: '',
      width: 40,
      dataCell: dataItem => <RoundedImage url={dataItem.avatar} name={dataItem.name} />,
      dialogDataCell: () => null,
    },
    {
      key: 'name',
      title: 'Name',
      width: 200,
      dataCell: dataItem => <span>{dataItem.name}</span>,
      dialogDataCell: () => null,
    },
    {
      key: 'licenseNumber',
      title: 'License',
      width: 100,
      dataCell: dataItem => <span>{dataItem.licenseNumber || '-'}</span>,
      dialogDataCell: () => null,
    },
    {
      key: 'phone',
      title: 'Mobile',
      width: 150,
      dataCell: dataItem => <span>{dataItem.phone}</span>,
      overflowBreakpoint: CSS_BREAKPOINTS.md,
    },
    {
      key: 'email',
      title: 'Email',
      width: 250,
      dataCell: dataItem => <span>{dataItem.email}</span>,
      overflowBreakpoint: CSS_BREAKPOINTS.md,
    },
    {
      key: 'createdAt',
      title: 'Joined',
      width: 150,
      dataCell: dataItem => <span>{formatDistanceToNow(new Date(dataItem.createdAt), { addSuffix: true })}</span>,
      overflowBreakpoint: CSS_BREAKPOINTS.md,
    },
    {
      className: 'd-md-none flex-shrink-0 flex-grow-0',
      key: 'actions',
      width: 20,
      dataCell: dataItem => <DataTableItemDialog columns={columns} dialogTitle={'Details'} dataItem={dataItem} />,
      dialogDataCell: () => null,
    },
  ];

  const sanitizedHtml = DOMPurify.sanitize(info.about);
  const sanitizedHistoryHtml = DOMPurify.sanitize(info.history);

  return (
    <div>
      <div className='bg-white'>
        <div className='container-fluid container-lg py-5 '>
          <div className='row align-items-center'>
            <div className='col-12 col-md-6'>
              <div className='pe-md-4'>
                <h3 className='mb-2 ts-color-tertiary'>WELCOME</h3>
                <h4 className='mb-4 ts-fw-900'>{info.name}</h4>
                <div className={cx('mb-4', css.paragraph16)} dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
              </div>
            </div>

            <div className='col-12 col-md-6 mt-4 mt-md-0'>
              <div
                className='rounded-3 ratio ratio-16x9'
                style={{
                  backgroundImage: `url(${info.aboutImageUrl || info.logoUrl})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
              />
            </div>
          </div>

          {info.history && (
            <div className='row align-items-center mt-5'>
              <div className='col-12 col-md-6 mt-4 mt-md-0'>
                <div
                  className='rounded-3 ratio ratio-16x9'
                  style={{
                    backgroundImage: `url(${info.historyImageUrl || info.logoUrl})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                  }}
                />
              </div>

              <div className='col-12 col-md-6'>
                <div className='pe-md-4'>
                  <h4 className='mb-4 '>Our History</h4>
                  <div
                    className={cx('mb-4', css.paragraph16)}
                    dangerouslySetInnerHTML={{ __html: sanitizedHistoryHtml }}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {contactsQuery.data && (
        <div>
          <div className='container-fluid container-lg py-5'>
            <h3 className='mb-4'>Leadership</h3>
            <div className='row g-4'>
              {[...contactsQuery.data]
                .sort((a, b) => {
                  const indexA = JOB_TITLE_ORDER.indexOf(a.jobTitle);
                  const indexB = JOB_TITLE_ORDER.indexOf(b.jobTitle);
                  // If both titles are not in the order array, maintain their relative position
                  if (indexA === -1 && indexB === -1) return 0;
                  // If only one title is not in the order array, put it at the end
                  if (indexA === -1) return 1;
                  if (indexB === -1) return -1;
                  // Otherwise sort by their position in the order array
                  return indexA - indexB;
                })
                .map(leader => (
                  <div key={leader.id} className='col-12 col-md-6 col-lg-4'>
                    <LeadershipCard {...leader} />
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}

      <div className='bg-white'>
        <div className='container-fluid container-lg py-5'>
          <h3 className='mb-4'>This season stats</h3>
          <div className='d-flex justify-content-between align-items-center overflow-auto'>
            {SEASON_STATS.map((stat, index) => (
              <ResumeItem
                key={stat.title}
                title={stat.title}
                value={stat.value}
                withDivider={index !== SEASON_STATS.length - 1}
              />
            ))}
          </div>
        </div>
      </div>

      <div>
        <div className='container-fluid container-lg py-5'>
          <h3 className='mb-4'>Officials</h3>
          <QueryLoader query={refereesQuery}>
            {({ data }) => (
              <>
                <Card>
                  <DataTable
                    data={data.referees}
                    columns={columns}
                    headerClassName='ts-fw-500 ts-fs-14'
                    style={{ boxShadow: 'none' }}
                  />
                </Card>
                <div className='mt-4'>
                  <Pagination model={pagination} />
                </div>
              </>
            )}
          </QueryLoader>
        </div>
      </div>
    </div>
  );
}
