import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Drawer } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { useAppRoutesContext } from 'src/routes/routes';
import { cx } from 'src/utils/styles';
import { useClub } from 'src/context/useClub';
import css from './TopBar.module.scss';
import { PrimaryButton } from '../buttons';

interface TopBarProps {}

export const TopBar: React.FC<TopBarProps> = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const {
    club: {
      info: { name, showCalendar, showContactUs, leagueFlag, rssUrl, showTeams },
      teams,
    },
  } = useClub();
  const routes = useAppRoutesContext();

  const menuItems = (
    <>
      <NavLink
        className={({ isActive }) => cx(isActive && 'ts-color-primary', 'ts-fs-16')}
        to={routes.home}
        onClick={() => setMobileMenuOpen(false)}
      >
        Home
      </NavLink>
      {showTeams && teams.length > 0 && (
        <NavLink
          className={({ isActive }) => cx(isActive && 'ts-color-primary', 'ts-fs-16')}
          to={routes.teams.base}
          onClick={() => setMobileMenuOpen(false)}
        >
          Teams
        </NavLink>
      )}
      {leagueFlag && (
        <NavLink
          className={({ isActive }) => cx(isActive && 'ts-color-primary', 'ts-fs-16')}
          to={routes.standings}
          onClick={() => setMobileMenuOpen(false)}
        >
          Standings
        </NavLink>
      )}
      {showCalendar && (
        <NavLink
          className={({ isActive }) => cx(isActive && 'ts-color-primary', 'ts-fs-16')}
          to={routes.calendar}
          onClick={() => setMobileMenuOpen(false)}
        >
          Calendar
        </NavLink>
      )}
      {rssUrl && (
        <NavLink
          className={({ isActive }) => cx(isActive && 'ts-color-primary', 'ts-fs-16')}
          to={routes.articles}
          onClick={() => setMobileMenuOpen(false)}
        >
          Articles
        </NavLink>
      )}
      {showContactUs && (
        <NavLink
          className={({ isActive }) => cx(isActive && 'ts-color-primary', 'ts-fs-16')}
          to={routes.contact}
          onClick={() => setMobileMenuOpen(false)}
        >
          Contact us
        </NavLink>
      )}
      <PrimaryButton className='ts-fw-500' onClick={() => window.open('https://coaches.teamsportz.pro', '_blank')}>
        Coaches login
      </PrimaryButton>
    </>
  );

  return (
    <div className={cx(css.wrapper, 'd-flex align-self-center align-items-center w-100 px-4 px-sm-5')}>
      <h3 className='mb-0 ts-text-overflow'>
        <NavLink to={routes.home} className='d-none d-sm-block fs-2 ts-text-overflow'>
          {name.toUpperCase()}
        </NavLink>
        <NavLink to={routes.home} className='d-block d-sm-none fs-5 ts-text-overflow'>
          {name.toUpperCase()}
        </NavLink>
      </h3>

      <div className='d-none d-sm-flex gap-4 align-self-center flex-grow-1 justify-content-sm-end flex-shrink-0 align-items-center'>
        {menuItems}
      </div>

      <div className='d-sm-none ms-auto'>
        <Button type='text' icon={<MenuOutlined className='text-white' />} onClick={() => setMobileMenuOpen(true)} />
      </div>

      <Drawer
        title={name.toUpperCase()}
        placement='right'
        onClose={() => setMobileMenuOpen(false)}
        open={mobileMenuOpen}
        className='d-sm-none'
      >
        <div className='d-flex flex-column gap-4'>{menuItems}</div>
      </Drawer>
    </div>
  );
};
