import React from 'react';
import { Card, CardTitle } from 'src/shared/widgets/Card';
import { cx } from 'src/utils/styles';
import css from './TeamCard.module.scss';
import { ClubCompetition } from 'src/api/getClubCompetitions';
import { useClub } from 'src/context/useClub';
import { useNavigate } from 'react-router-dom';
import { useAppRoutesContext } from 'src/routes/routes';
import { PrimaryButton } from 'src/shared/buttons';
import { toTitleCase } from 'src/utils/strings';
import { CSS_BREAKPOINTS } from 'src/constants';
import { useIsMobile } from 'src/utils/hooks/useIsMobile';
import { format, differenceInDays } from 'date-fns';

interface CompetitionAvatarProps {
  logoUrl?: string | null;
  isMobile: boolean;
  eventStart: string | null;
  entryDatePassed: boolean;
}

export const CompetitionAvatar: React.FC<CompetitionAvatarProps> = ({ logoUrl, eventStart, entryDatePassed }) => {
  const daysToEvent = differenceInDays(eventStart!, new Date());

  return (
    <div className='position-relative'>
      <img className={cx('mb-sm-0', css.logoCompetition)} src={`${logoUrl || '/assets/img/shield_place_holder.png'}`} />

      <div className='position-absolute m-2 top-0 end-0'>
        {entryDatePassed ? (
          <span className={cx('badge text-white px-3 py-2', css.statusbadgeDisabled)}>CLOSED</span>
        ) : daysToEvent <= 5 ? (
          <h6>
            <span className={cx('badge text-white px-3 py-2', css.statusbadgeWarning)}>CLOSING SOON</span>
          </h6>
        ) : (
          <h6>
            <span className={cx('badge px-3 py-2', css.statusbadgeSuccess)}>OPEN</span>
          </h6>
        )}
      </div>
    </div>
  );
};

interface CompetitionCardProps {
  competition: ClubCompetition;
}

export const CompetitionCard: React.FC<CompetitionCardProps> = ({
  competition: {
    id,
    name,
    event: { end: eventEnd, start: eventStart, eventEntryDeadlineDate },
    ageGroup,
    category,
    product: { cost: productCost },
  },
}) => {
  const { club } = useClub();
  const routes = useAppRoutesContext();
  const navigate = useNavigate();
  const isMobile = useIsMobile(CSS_BREAKPOINTS.sm);

  const formatDate = (dateWithTime: string) => {
    const date = new Date(dateWithTime);
    const hours = date.getUTCHours();
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const period = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12;
    const formattedTime = `${formattedHours}:${minutes} ${period}`;
    return formattedTime;
  };

  const entryDatePassed = new Date() > new Date(eventEntryDeadlineDate!);
  //
  return (
    <Card className={cx(css.competitionWrapper, 'p-0 d-flex flex-column w-100')}>
      <div className={`d-flex flex-column flex-sm-grow-1 w-100 p-3 gap-3`}>
        <CompetitionAvatar
          logoUrl={club.info.logoUrl}
          isMobile={isMobile}
          eventStart={eventStart}
          entryDatePassed={eventEntryDeadlineDate ? entryDatePassed : false}
        />
        <div className={cx(`flex-grow-1 flex-shrink-1 d-flex w-100 flex-column justify-content-sm-center`)}>
          <CardTitle className='mb-2'>
            {toTitleCase(name)} - {toTitleCase(ageGroup || '')} {toTitleCase(category || '')}
          </CardTitle>
          {eventStart && (
            <p className={cx('ts-fs-14 mb-2', css.faintBlackColor)}>
              <span className='ts-fw-400'>Date:</span> {format(eventStart, 'dd MMMM yyyy')}
            </p>
          )}
          {eventStart && eventEnd && (
            <p className={cx('ts-fs-14', css.faintBlackColor)}>
              <span className='ts-fw4500'>Time:</span> {formatDate(eventStart)}
            </p>
          )}
          {eventEntryDeadlineDate && (
            <p className={cx('ts-fs-14', css.faintBlackColor)} style={{ marginTop: '-10px' }}>
              <span className='ts-fw4500'>Entry deadline:</span>{' '}
              {format(eventEntryDeadlineDate, 'dd MMMM yyyy - h:mm a')}
            </p>
          )}
          {productCost && (
            <p className={cx('ts-fs-14', css.faintBlackColor)}>
              <span className='ts-fw-400'>Entry cost:</span> £{productCost} p/athlete
            </p>
          )}
        </div>
        <PrimaryButton
          className='w-100 w-sm-auto ts-fs-14 ts-fw-500'
          style={{ padding: '0.75rem 1.5rem', height: 'auto' }}
          onClick={() => navigate(`${routes.competitions.competition(id)}?code=${club.info.code}`)}
          disabled={eventEntryDeadlineDate ? entryDatePassed : false}
        >
          ENTER NOW
        </PrimaryButton>
      </div>
    </Card>
  );
};
