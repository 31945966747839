import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useClub } from 'src/context/useClub';
import { APP_ROUTES } from 'src/routes/routes';
import { PrimaryInvertedButton, CancelButton, SecondaryButton } from 'src/shared/buttons';
import css from './index.module.scss';
import { cx } from 'src/utils/styles';
import UpcomingMatches from 'src/pages/home/games/UpcomingMatches';
import { useGetTeamClubInfoQuery } from './queries/getTeamInfoQuery';
import { Mail, MapPin, Phone } from 'react-feather';
import { useGetClubTeamsQuery } from './queries/getClubTeams';
import { useGetTeamCoachesQuery } from './queries/getTeamCoaches';
import { LeadershipCard } from 'src/pages/about';
import { Card } from 'src/shared/widgets/Card';
import PlayersOnboardingInfo from 'src/pages/home/PlayersOnboardingInfo';
import { formatReadableDateAndTime } from '../../../utils/dates';
import { useContactsQuery } from 'src/pages/about/queries/getContactsQuery';

export const TeamPage = () => {
  const { teamId } = useParams<{ teamId: string }>();
  const navigate = useNavigate();
  const {
    club: { teams },
  } = useClub();

  const team = teams.find(team => team.id === Number(teamId));
  const { data: teamInfo } = useGetTeamClubInfoQuery(team?.orgSiteCode);
  const { data: clubTeams } = useGetClubTeamsQuery(team?.orgSiteCode);
  const { teamVenue } = useGetTeamCoachesQuery(team?.id || 0, team?.orgSiteCode);
  const [modalOpened, setModalOpened] = React.useState(false);
  const [selectedTeam, setSelectedTeam] = React.useState<{ name: string; code: string } | null>(null);
  const contactsQuery = useContactsQuery(team?.orgSiteCode || '');

  // Sort teams to ensure current team appears first
  const sortedTeams = React.useMemo(() => {
    if (!clubTeams || !team) return [];

    return [
      ...clubTeams.filter(clubTeam => clubTeam.id === team.id),
      ...clubTeams.filter(clubTeam => clubTeam.id !== team.id),
    ];
  }, [clubTeams, team]);

  const handleJoinTeam = (teamName: string, teamCode: string) => {
    setSelectedTeam({ name: teamName, code: teamCode });
    setModalOpened(true);
  };

  return (
    <div className='mt-2 container-fluid container-lg py-5'>
      {modalOpened && (
        <PlayersOnboardingInfo
          close={setModalOpened}
          teamName={selectedTeam?.name || ''}
          teamCode={selectedTeam?.code || ''}
        />
      )}

      <PrimaryInvertedButton onClick={() => navigate(APP_ROUTES.teams.base)} className='ts-fs-18 ts-fw-500 ps-0'>
        &lt; Back to teams
      </PrimaryInvertedButton>

      <div className='d-flex flex-column-reverse flex-md-row align-items-center gap-3 justify-content-between mt-5'>
        <div className='col-12 col-md-6'>
          <h3 className='ts-fw-900 mb-3'>{teamInfo?.name}</h3>
          <div className='d-flex flex-column gap-2'>
            {teamInfo?.email && (
              <a
                className='d-flex gap-3 align-items-center ts-fs-18 text-decoration-underline'
                href={`mailto:${teamInfo.email}`}
              >
                <Mail className='ts-color-primary' size={16} />
                {teamInfo.email}
              </a>
            )}

            {teamInfo?.phoneNumber && (
              <a className='d-flex gap-3 align-items-center ts-fs-18' href={`tel:${teamInfo.phoneNumber}`}>
                <Phone className='ts-color-primary' size={16} />
                {teamInfo.phoneNumber}
              </a>
            )}

            {(teamInfo?.address || teamVenue) && (
              <a
                className='d-flex gap-3 align-items-center ts-fs-18'
                href={`https://maps.google.com?q=${teamInfo?.address || teamVenue}`}
                target='_blank'
              >
                <MapPin className='ts-color-primary' size={16} />
                {teamInfo?.address || teamVenue}
              </a>
            )}
          </div>
        </div>

        <div className='col-12 col-md-6 d-none d-md-flex justify-content-center'>
          <img
            src={team?.logoUrl || 'assets/img/shield_place_holder.png'}
            alt={team?.name}
            className={cx(css.teamLogo)}
          />
        </div>
      </div>

      <div className='mt-5'>
        <h3>Coaches and staff</h3>

        {contactsQuery.data && contactsQuery.data.length > 0 ? (
          <div className='row'>
            {contactsQuery.data?.map((coach, index) => (
              <div key={index} className='col-12 col-md-4 mb-4'>
                <LeadershipCard
                  name={coach?.name || ''}
                  jobTitle={coach?.jobTitle || ''}
                  avatar={coach?.avatar || ''}
                />
              </div>
            ))}
          </div>
        ) : (
          <p className='ts-fs-16'>No coaches or staff found</p>
        )}
      </div>

      <div className='mt-5'>
        <h3>Join us!</h3>
        <div className='row g-0 g-md-5'>
          {sortedTeams.map(clubTeam => (
            <div key={clubTeam.id} className='col-12 col-md-6 col-lg-4 mb-5'>
              <Card className='p-0 d-flex flex-column h-100 overflow-hidden'>
                <div className='p-4 p-lg-5 d-flex flex-column h-100' style={{ justifyContent: 'space-between' }}>
                  <div>
                    <div
                      className='mx-auto mb-4'
                      style={{
                        width: '200px',
                        height: '200px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <img
                        src={clubTeam.teamAvatar || '/assets/img/shield_place_holder.png'}
                        alt={clubTeam.teamName}
                        style={{
                          maxWidth: '100%',
                          maxHeight: '100%',
                          objectFit: 'contain',
                          borderRadius: '15px',
                        }}
                      />
                    </div>

                    <h4 className='ts-fw-700 mb-3'>{clubTeam.teamName}</h4>

                    {(clubTeam.id === team?.id || clubTeam.leagueId) && (
                      <div className='mb-3'>
                        <p className='mb-1 ts-fw-900 ts-fs-14 '>Team Code:</p>
                        <p className='mb-0 ts-fs-14' style={{ fontFamily: 'monospace' }}>
                          {clubTeam.teamCode}
                        </p>
                      </div>
                    )}
                  </div>

                  <div>
                    {!clubTeam.leagueId && (
                      <div className='mb-3'>
                        <p className='mb-1 ts-fw-900 ts-fs-14'>
                          {clubTeam.nextPractice?.name
                            ? `Next session — ${clubTeam.nextPractice.name}:`
                            : 'Weekly — next session:'}
                        </p>
                        <p className='mb-0 ts-fs-14'>
                          {clubTeam.nextPractice?.time
                            ? formatReadableDateAndTime(clubTeam.nextPractice.time.replace('Z', ''))
                            : '--'}
                        </p>
                      </div>
                    )}
                    <div className='mb-3'>
                      <p className='mb-1 ts-fw-900 ts-fs-14'>Venue:</p>
                      <a
                        className='mb-0 ts-fs-14'
                        href={`https://maps.google.com?q=${teamInfo?.address || teamVenue}`}
                        target='_blank'
                      >
                        {teamInfo?.address || teamVenue || '--'}
                      </a>
                    </div>
                  </div>

                  <div>
                    <p className='mb-4 ts-fs-14'>
                      {clubTeam.id === team?.id || clubTeam.leagueId ? (
                        <>
                          Your request to join the squad will be approved by team manager.{' '}
                          <a href={`mailto:${teamInfo?.email}`} className='ts-color-primary'>
                            Contact us
                          </a>{' '}
                          about joining.
                        </>
                      ) : (
                        <>
                          <a href={`mailto:${teamInfo?.email}`} className='ts-color-primary'>
                            Contact us
                          </a>{' '}
                          about joining.
                        </>
                      )}
                    </p>

                    <div className='d-flex gap-2'>
                      {clubTeam.leagueId ? (
                        <SecondaryButton
                          className='w-100 ts-fs-14 ts-fw-500'
                          style={{ padding: '0.75rem 1.5rem', height: 'auto', backgroundColor: '#4a90e2' }}
                          onClick={() =>
                            handleJoinTeam(clubTeam.teamName, clubTeam.id === team?.id ? team.code : 'HYU6J')
                          }
                        >
                          Join On The Mobile App
                        </SecondaryButton>
                      ) : (
                        <CancelButton
                          className='w-100 ts-fs-14 ts-fw-500'
                          style={{ padding: '0.75rem 1.5rem', height: 'auto', backgroundColor: '#4a4a4a' }}
                          onClick={() => {
                            if (teamInfo?.email) {
                              window.location.href = `mailto:${teamInfo.email}`;
                            }
                          }}
                        >
                          Contact Us
                        </CancelButton>
                      )}
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          ))}
        </div>
      </div>

      <div>
        <div className='container-fluid container-lg py-5'>
          <UpcomingMatches />
        </div>
      </div>
    </div>
  );
};
