import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { FullScreenSpinner } from 'src/shared/layout/FullScreenSpinner';
import { router } from 'src/routes/router';
import { ChakraProvider } from '@chakra-ui/react';
import { ConfigProvider } from 'antd';

import 'src/styles/main.scss';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <ConfigProvider
    theme={{
      token: {
        // Seed Token
        colorPrimary: '#fc5352',
      },

      components: {
        Input: {
          colorPrimary: '#4b8ee2',
          hoverBorderColor: '#77aae9',
        },
      },
    }}
  >
    <ChakraProvider disableGlobalStyle={true}>
      <RouterProvider router={router} fallbackElement={<FullScreenSpinner />} />
    </ChakraProvider>
  </ConfigProvider>,
);
