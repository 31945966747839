import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Form, Input, Radio, DatePicker, Select, Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { differenceInYears } from 'date-fns';
import { CardTitle } from 'src/shared/widgets/Card';
import { useClub } from 'src/context/useClub';
import { EntryFormField } from 'src/api/getClubCompetitions';
import DefaultPlayerFormFields from './DefaultPlayerFormFields';

const { Option } = Select;

export const HEIGHT_OPTIONS = [
  { label: '4\'0" / 121.92 cm', value: '121.92' },
  { label: '4\'1" / 124.46 cm', value: '124.46' },
  { label: '4\'2" / 127.00 cm', value: '127.00' },
  { label: '4\'3" / 129.54 cm', value: '129.54' },
  { label: '4\'4" / 132.08 cm', value: '132.08' },
  { label: '4\'5" / 134.62 cm', value: '134.62' },
  { label: '4\'6" / 137.16 cm', value: '137.16' },
  { label: '4\'7" / 139.70 cm', value: '139.70' },
  { label: '4\'8" / 142.24 cm', value: '142.24' },
  { label: '4\'9" / 144.78 cm', value: '144.78' },
  { label: '4\'10" / 147.32 cm', value: '147.32' },
  { label: '4\'11" / 149.86 cm', value: '149.86' },
  { label: '5\'0" / 152.40 cm', value: '152.40' },
  { label: '5\'1" / 154.94 cm', value: '154.94' },
  { label: '5\'2" / 157.48 cm', value: '157.48' },
  { label: '5\'3" / 160.02 cm', value: '160.02' },
  { label: '5\'4" / 162.56 cm', value: '162.56' },
  { label: '5\'5" / 165.10 cm', value: '165.10' },
  { label: '5\'6" / 167.64 cm', value: '167.64' },
  { label: '5\'7" / 170.18 cm', value: '170.18' },
  { label: '5\'8" / 172.72 cm', value: '172.72' },
  { label: '5\'9" / 175.26 cm', value: '175.26' },
  { label: '5\'10" / 177.80 cm', value: '177.80' },
  { label: '5\'11" / 180.34 cm', value: '180.34' },
  { label: '6\'0" / 182.88 cm', value: '182.88' },
  { label: '6\'1" / 185.42 cm', value: '185.42' },
  { label: '6\'2" / 187.96 cm', value: '187.96' },
  { label: '6\'3" / 190.50 cm', value: '190.50' },
  { label: '6\'4" / 193.04 cm', value: '193.04' },
  { label: '6\'5" / 195.58 cm', value: '195.58' },
  { label: '6\'6" / 198.12 cm', value: '198.12' },
  { label: '6\'7" / 200.66 cm', value: '200.66' },
  { label: '6\'8" / 203.20 cm', value: '203.20' },
  { label: '6\'9" / 205.74 cm', value: '205.74' },
  { label: '6\'10" / 208.28 cm', value: '208.28' },
  { label: '6\'11" / 210.82 cm', value: '210.82' },
  { label: '7\'0" / 213.36 cm', value: '213.36' },
  { label: '7\'1" / 215.90 cm', value: '215.90' },
  { label: '7\'2" / 218.44 cm', value: '218.44' },
  { label: '7\'3" / 220.98 cm', value: '220.98' },
  { label: '7\'4" / 223.52 cm', value: '223.52' },
  { label: '7\'5" / 226.06 cm', value: '226.06' },
  { label: '7\'6" / 228.60 cm', value: '228.60' },
  { label: '7\'7" / 231.14 cm', value: '231.14' },
  { label: '7\'8" / 233.68 cm', value: '233.68' },
  { label: '7\'9" / 236.22 cm', value: '236.22' },
  { label: '7\'10" / 238.76 cm', value: '238.76' },
  { label: '7\'11" / 241.30 cm', value: '241.30' },
];

export const ETHNICITY_OPTIONS = [
  { value: 'Asian or Asian British - Bangladeshi', label: 'Asian or Asian British - Bangladeshi' },
  { value: 'Asian or Asian British - Indian', label: 'Asian or Asian British - Indian' },
  { value: 'Asian or Asian British - Other', label: 'Asian or Asian British - Other' },
  { value: 'Asian or Asian British - Pakistani', label: 'Asian or Asian British - Pakistani' },
  { value: 'Black or Black British - African', label: 'Black or Black British - African' },
  { value: 'Black or Black British - Caribbean', label: 'Black or Black British - Caribbean' },
  { value: 'Black or Black British - Other', label: 'Black or Black British - Other' },
  { value: 'Chinese', label: 'Chinese' },
  { value: 'Mixed - Other', label: 'Mixed - Other' },
  { value: 'Mixed - White and Asian', label: 'Mixed - White and Asian' },
  { value: 'Mixed - White and Black African', label: 'Mixed - White and Black African' },
  { value: 'Mixed - White and Black Caribbean', label: 'Mixed - White and Black Caribbean' },
  { value: 'Other Ethnic Group', label: 'Other Ethnic Group' },
  { value: 'White - British', label: 'White - British' },
  { value: 'White - Irish', label: 'White - Irish' },
  { value: 'White - Other', label: 'White - Other' },
  { value: 'Prefer not to say', label: 'Prefer not to say' },
];

export const RELATIONSHIP_OPTIONS = [
  { value: 'mother', label: 'Mother' },
  { value: 'father', label: 'Father' },
  { value: 'parent', label: 'Parent' },
  { value: 'step-mother', label: 'Step-Mother' },
  { value: 'step-father', label: 'Step-Father' },
  { value: 'guardian', label: 'Guardian' },
  { value: 'grandmother', label: 'Grandmother' },
  { value: 'grandfather', label: 'Grandfather' },
  { value: 'brother', label: 'Brother' },
  { value: 'sister', label: 'Sister' },
  { value: 'auntie', label: 'Auntie' },
  { value: 'uncle', label: 'Uncle' },
  { value: 'cousin', label: 'Cousin' },
  { value: 'friend', label: 'Friend' },
  { value: 'other', label: 'Other' },
];

const COUNTY_OPTIONS = [
  'Barking and Dagenham',
  'Barnet',
  'Bedfordshire',
  'Berkshire',
  'Bexley',
  'Brent',
  'Bristol',
  'Bromley',
  'Buckinghamshire',
  'Cambridgeshire',
  'Camden',
  'Cheshire',
  'Cornwall',
  'Croydon',
  'Cumbria',
  'Derbyshire',
  'Devon',
  'Dorset',
  'Durham',
  'Ealing',
  'East Riding of Yorkshire',
  'Enfield',
  'Essex',
  'Gloucestershire',
  'Greater Manchester',
  'Greenwich',
  'Hackney',
  'Hammersmith and Fulham',
  'Hampshire',
  'Haringey',
  'Harrow',
  'Havering',
  'Herefordshire',
  'Hertfordshire',
  'Hillingdon',
  'Hounslow',
  'Islington',
  'Kensington and Chelsea',
  'Kent',
  'Kingston upon Thames',
  'Lambeth',
  'Lancashire',
  'Leicestershire',
  'Lewisham',
  'Lincolnshire',
  'Merseyside',
  'Merton',
  'Newham',
  'Norfolk',
  'North Yorkshire',
  'Northamptonshire',
  'Northumberland',
  'Nottinghamshire',
  'Oxfordshire',
  'Redbridge',
  'Richmond upon Thames',
  'Rutland',
  'Shropshire',
  'Somerset',
  'South Yorkshire',
  'Southwark',
  'Staffordshire',
  'Suffolk',
  'Surrey',
  'Sussex',
  'Sutton',
  'Tower Hamlets',
  'Tyne and Wear',
  'Waltham Forest',
  'Wandsworth',
  'Warwickshire',
  'West Midlands',
  'West Yorkshire',
  'Westminster',
  'Wiltshire',
  'Worcestershire',
  'Other - Scotland',
  'Other - Wales',
  'Other - Ireland',
  'Other - Europe',
  'Other - America',
];

const REGION_OPTIONS = [
  { value: 'East', label: 'East - Bedfordshire, Cambridgeshire, Essex, Hertfordshire, Norfolk, Suffolk' },
  {
    value: 'East Mids',
    label: 'East Mids - Derbyshire, Leicestershire, Lincolnshire, Northamptonshire, Nottinghamshire, Rutland',
  },
  {
    value: 'London North',
    label:
      'London North - Barking and Dagenham, Barnet, Brent, Camden, Ealing, Enfield, Hackney, Hammersmith and Fulham, Haringey, Harrow, Havering, Hillingdon, Hounslow, Islington, Kensington and Chelsea, Newham, Redbridge, Tower Hamlets, Waltham Forest, Westminster',
  },
  {
    value: 'London South',
    label:
      'London South - Bexley, Bromley, Croydon, Greenwich, Kingston upon Thames, Lambeth, Lewisham, Merton, Richmond upon Thames, Southwark, Sutton, Wandsworth',
  },
  { value: 'North East', label: 'North East - Durham, Northumberland, Tyne and Wear' },
  { value: 'North West', label: 'North West - Cheshire, Cumbria, Greater Manchester, Lancashire, Merseyside' },
  { value: 'South', label: 'South - Berkshire, Buckinghamshire, Hampshire, Oxfordshire' },
  { value: 'South East', label: 'South East - Kent, Surrey, Sussex' },
  { value: 'South West', label: 'South West - Bristol, Cornwall, Devon, Dorset, Gloucestershire, Somerset, Wiltshire' },
  {
    value: 'West Midlands',
    label: 'West Midlands - Herefordshire, Shropshire, Staffordshire, Warwickshire, West Midlands, Worcestershire',
  },
  {
    value: 'Yorkshire',
    label: 'Yorkshire - East Riding of Yorkshire, North Yorkshire, South Yorkshire, West Yorkshire',
  },
];

const DISABILITY_OPTIONS = [
  'Hearing impairment',
  'Visual impairment',
  'Mental health',
  'Learning disability',
  'Physical disability',
  'Do not wish to disclose',
  'Not applicable',
];

export interface PlayerFormFieldsProps {
  fieldNamePrefix?: (string | number)[] | undefined;
  dobRange: {
    oldestDob: string | null | undefined;
    youngestDob: string | null | undefined;
  };
  category: string | undefined;
  onDateOfBirthChange: (isOver18: boolean | null) => void;
  isOver18: boolean | null;
  formTemplate: EntryFormField[] | undefined;
  sportName: string;
}

const PlayerFormFields: React.FC<PlayerFormFieldsProps> = ({
  fieldNamePrefix,
  dobRange,
  category,
  onDateOfBirthChange,
  isOver18,
  formTemplate,
  sportName,
}) => {
  const {
    club: { info },
  } = useClub();
  const form = Form.useFormInstance();

  const [useParentAsEmergency, setUseParentAsEmergency] = useState(false);
  const [showPhotographyDetails, setShowPhotographyDetails] = useState(false);
  const [showMedicalDetails, setShowMedicalDetails] = useState(false);

  const memoizedFieldNamePrefix = useMemo(
    () => fieldNamePrefix,
    [fieldNamePrefix ? JSON.stringify(fieldNamePrefix) : null],
  );

  // Create the field name with optional prefix for Form.List
  const getFieldName = useCallback(
    (name: string) => {
      if (memoizedFieldNamePrefix) {
        return [...memoizedFieldNamePrefix, name];
      }
      return name;
    },
    [memoizedFieldNamePrefix],
  );

  const calculateIsOver18 = (dateOfBirth: Date) => {
    const currentDate = new Date();
    return differenceInYears(currentDate, dateOfBirth) >= 18;
  };

  const handleDateOfBirthChange = (_dateOfBirth: Date, dateString: string | string[]) => {
    const parsedDate = new Date(dateString as string);
    if (parsedDate) {
      onDateOfBirthChange(calculateIsOver18(parsedDate));
    } else {
      onDateOfBirthChange(null);
    }
  };

  const copyParentToEmergency = (e: CheckboxChangeEvent) => {
    const checked = e.target.checked;

    // Update state
    setUseParentAsEmergency(checked);

    if (checked && form) {
      try {
        // Get parent details
        let parentFirstName;
        let parentLastName;
        let parentMobileNumber;

        if (Array.isArray(memoizedFieldNamePrefix)) {
          // For team mode, we need to access the nested structure correctly
          const formValues = form.getFieldsValue(true);
          const index = memoizedFieldNamePrefix[0];
          const playerData = formValues.items?.[index] || {};

          parentFirstName = playerData.parent_first_name;
          parentLastName = playerData.parent_last_name;
          parentMobileNumber = playerData.parent_mobile_number;

          if (parentFirstName || parentLastName || parentMobileNumber) {
            // create name paths for nested fields
            const firstNamePath = ['items', index, 'emergencyFirstName1'];
            const lastNamePath = ['items', index, 'emergencyLastName1'];
            const mobilePath = ['items', index, 'emergencyMobileNumber1'];
            const relationshipPath = ['items', index, 'relationship1'];

            form.setFields([
              { name: firstNamePath, value: parentFirstName },
              { name: lastNamePath, value: parentLastName },
              { name: mobilePath, value: parentMobileNumber },
              { name: relationshipPath, value: 'parent' },
            ]);

            // triggering validation (to force rerender)
            form.validateFields([firstNamePath, lastNamePath, mobilePath, relationshipPath]);
          }
        } else {
          // Single entry mode
          parentFirstName = form.getFieldValue(getFieldName('parent_first_name'));
          parentLastName = form.getFieldValue(getFieldName('parent_last_name'));
          parentMobileNumber = form.getFieldValue(getFieldName('parent_mobile_number'));

          form.setFieldsValue({
            [getFieldName('emergencyFirstName1') as string]: parentFirstName,
            [getFieldName('emergencyLastName1') as string]: parentLastName,
            [getFieldName('emergencyMobileNumber1') as string]: parentMobileNumber,
            [getFieldName('relationship1') as string]: 'parent',
          });
        }
      } catch (error) {
        console.error('Error copying parent to emergency contact:', error);
      }
    } else if (!checked && form) {
      // When checkbox is unchecked, clear the emergency contact fields
      try {
        if (Array.isArray(memoizedFieldNamePrefix)) {
          // For team mode
          const index = memoizedFieldNamePrefix[0];
          const firstNamePath = ['items', index, 'emergencyFirstName1'];
          const lastNamePath = ['items', index, 'emergencyLastName1'];
          const mobilePath = ['items', index, 'emergencyMobileNumber1'];
          const relationshipPath = ['items', index, 'relationship1'];

          form.setFields([
            { name: firstNamePath, value: '' },
            { name: lastNamePath, value: '' },
            { name: mobilePath, value: '' },
            { name: relationshipPath, value: '' },
          ]);

          // To force rerender
          form.validateFields([firstNamePath, lastNamePath, mobilePath, relationshipPath]);
        } else {
          // Single entry mode
          form.setFieldsValue({
            [getFieldName('emergencyFirstName1') as string]: '',
            [getFieldName('emergencyLastName1') as string]: '',
            [getFieldName('emergencyMobileNumber1') as string]: '',
            [getFieldName('relationship1') as string]: '',
          });
        }
      } catch (error) {
        console.error('Error clearing emergency contact fields:', error);
      }
    }
  };

  useEffect(() => {
    if (!form) return;

    try {
      if (useParentAsEmergency) {
        // Get nested form values properly
        const parentFieldNames = {
          firstName: getFieldName('parent_first_name'),
          lastName: getFieldName('parent_last_name'),
          mobileNumber: getFieldName('parent_mobile_number'),
        };

        let parentFirstName;
        let parentLastName;
        let parentMobileNumber;

        if (Array.isArray(memoizedFieldNamePrefix)) {
          // For team forms, we get the complete form values and extract what we need
          const allFormValues = form.getFieldsValue(true);

          const itemValues = allFormValues.items && allFormValues.items[memoizedFieldNamePrefix[0]];

          // Extract the parent details from the nested structure
          parentFirstName = itemValues?.parent_first_name;
          parentLastName = itemValues?.parent_last_name;
          parentMobileNumber = itemValues?.parent_mobile_number;
        } else {
          // For single entry, we use direct access
          parentFirstName = form.getFieldValue(parentFieldNames.firstName);
          parentLastName = form.getFieldValue(parentFieldNames.lastName);
          parentMobileNumber = form.getFieldValue(parentFieldNames.mobileNumber);
        }

        // Create the emergency contact field names
        const emergencyFieldNames = {
          firstName: getFieldName('emergencyFirstName1'),
          lastName: getFieldName('emergencyLastName1'),
          mobileNumber: getFieldName('emergencyMobileNumber1'),
          relationship: getFieldName('relationship1'),
        };

        // For team entry mode we use setFields instead of setFieldsValue
        if (Array.isArray(memoizedFieldNamePrefix)) {
          if (parentFirstName || parentLastName || parentMobileNumber) {
            form.setFieldValue(emergencyFieldNames.firstName, parentFirstName || '');
            form.setFieldValue(emergencyFieldNames.lastName, parentLastName || '');
            form.setFieldValue(emergencyFieldNames.mobileNumber, parentMobileNumber || '');
            form.setFieldValue(emergencyFieldNames.relationship, 'parent');

            // Use setFields for backup
            form.setFields([
              {
                name: emergencyFieldNames.firstName,
                value: parentFirstName || '',
                touched: true,
              },
              {
                name: emergencyFieldNames.lastName,
                value: parentLastName || '',
                touched: true,
              },
              {
                name: emergencyFieldNames.mobileNumber,
                value: parentMobileNumber || '',
                touched: true,
              },
              {
                name: emergencyFieldNames.relationship,
                value: 'parent',
                touched: true,
              },
            ]);

            // Force validation to trigger re-render
            form.validateFields([
              emergencyFieldNames.firstName,
              emergencyFieldNames.lastName,
              emergencyFieldNames.mobileNumber,
              emergencyFieldNames.relationship,
            ]);
          }
        } else {
          // For single entry mode, use setFieldsValue
          const formValues: Record<string, string> = {};
          if (parentFirstName) formValues[emergencyFieldNames.firstName as string] = parentFirstName;
          if (parentLastName) formValues[emergencyFieldNames.lastName as string] = parentLastName;
          if (parentMobileNumber) formValues[emergencyFieldNames.mobileNumber as string] = parentMobileNumber;
          formValues[emergencyFieldNames.relationship as string] = 'parent';

          // Only update form if we have values to set
          if (Object.keys(formValues).length > 0) {
            form.setFieldsValue(formValues);
          }
        }
      } else {
        // Only clear fields when explicitly unchecking the box, not on initial render
        if (useParentAsEmergency === false) {
          const emergencyFieldNames = {
            firstName: getFieldName('emergencyFirstName1'),
            lastName: getFieldName('emergencyLastName1'),
            mobileNumber: getFieldName('emergencyMobileNumber1'),
            relationship: getFieldName('relationship1'),
          };

          if (Array.isArray(memoizedFieldNamePrefix)) {
            // Team form mode - use setFields
            form.setFields([
              {
                name: emergencyFieldNames.firstName,
                value: '',
              },
              {
                name: emergencyFieldNames.lastName,
                value: '',
              },
              {
                name: emergencyFieldNames.mobileNumber,
                value: '',
              },
              {
                name: emergencyFieldNames.relationship,
                value: '',
              },
            ]);
          } else {
            // Single entry mode - use setFieldsValue
            const formValues: Record<string, string> = {
              [emergencyFieldNames.firstName as string]: '',
              [emergencyFieldNames.lastName as string]: '',
              [emergencyFieldNames.mobileNumber as string]: '',
              [emergencyFieldNames.relationship as string]: '',
            };
            form.setFieldsValue(formValues);
          }
        }
      }
    } catch (error) {
      console.error('Error handling emergency contact details:', error);
    }
  }, [useParentAsEmergency, form, getFieldName, memoizedFieldNamePrefix]);

  // If no formTemplate is provided, use all fields with default settings
  if (!formTemplate || formTemplate.length === 0) {
    return (
      <DefaultPlayerFormFields
        getFieldName={getFieldName}
        dobRange={dobRange}
        category={category}
        handleDateOfBirthChange={handleDateOfBirthChange}
        isOver18={isOver18}
        COUNTY_OPTIONS={COUNTY_OPTIONS}
        REGION_OPTIONS={REGION_OPTIONS}
        DISABILITY_OPTIONS={DISABILITY_OPTIONS}
        useParentAsEmergency={useParentAsEmergency}
        copyParentToEmergency={copyParentToEmergency}
        clubName={info.name}
        sportName={sportName}
        email={info.email}
      />
    );
  }

  const renderField = (field: EntryFormField, index: number) => {
    if (!field.use) return null;

    // This is because we're not using label for the photography consent field
    if (field.name === 'Photography, Filming and Live Streaming Parent Consent') {
      return (
        <div id='photography-consent' key={`field-${index}`}>
          <div>
            <CardTitle className='mb-2'>{field.name}</CardTitle>
          </div>

          {!showPhotographyDetails ? (
            <div className='mb-3'>
              <p className='ts-fs-14 mb-2'>
                In accordance with our Safeguarding Policy, Photography Guidance and Photography and Video Advice (CPSU)
                we will not permit photographs, video or...
                <span
                  className='ts-color-primary ml-1'
                  style={{ cursor: 'pointer' }}
                  onClick={() => setShowPhotographyDetails(true)}
                >
                  read more
                </span>
              </p>
            </div>
          ) : (
            <div className='mb-3'>
              <p className='ts-fs-14 mb-2'>
                In accordance with our Safeguarding Policy, Photography Guidance and Photography and Video Advice (CPSU)
                we will not permit photographs, video or other images to be taken without their consent.
              </p>

              <p className='ts-fs-14 mb-2'>
                {info.name} will follow the guidance for the use of photographs and videos.
              </p>

              <p className='ts-fs-14 mb-2'>
                {info.name} will take all steps to ensure these images are used solely for the purposes they are
                intended. If you become aware that these images are being used inappropriately you should inform
                Basketball England immediately.
              </p>

              <ul>
                <li>
                  I consent for {info.name} to utilise my first and last name, voice, statements, photograph, image,
                  likeness, actions in any live or recorded form (including, but not limited to, any form of
                  photography, video, display, web content or other transmission or reproduction), in whole or in part,
                  for promotional, training and assessment.
                </li>

                <li>
                  I consent for {info.name} to live stream games as part of the sport activities that I am involved in
                  and recognise that this may include my voice, image, likeness and actions.
                </li>

                <li>
                  I can confirm that I have read, or been made aware of, the organisation's photography and videoing
                  policy.
                </li>

                <li>
                  I can confirm that I have read, or been made aware of how the organisation will use these images or
                  videos in future and how these images or videos will be stored within the organisation.
                </li>

                <li>I understand images may be used by {info.name} outside partners.</li>

                <li>
                  I consent to {info.name} and trusted partners to utilise my image in whole or in part for joint
                  promotional purposes.
                </li>

                <li>
                  I understand that consent lasts for one year, and that if I wish to remove my consent I must contact{' '}
                  {info.name} directly.
                </li>
              </ul>
              <span
                className='ts-color-primary ml-1'
                style={{ cursor: 'pointer' }}
                onClick={() => setShowPhotographyDetails(false)}
              >
                read less
              </span>
            </div>
          )}

          <div>Do you agree to all of the photography, filming and live streaming consents detailed above?</div>

          <Form.Item
            className='mt-3'
            name={getFieldName('photographyConsentParent')}
            rules={[{ required: field.required, message: 'Please select your consent' }]}
          >
            <Radio.Group>
              <Radio value={1}>Yes, I consent</Radio>
              <Radio value={0}>No, I don't consent</Radio>
            </Radio.Group>
          </Form.Item>
        </div>
      );
    }

    // Special case for Competition Consent, just like Photography Consent
    if (field.name === 'Competition Consent') {
      return (
        <div id='medical-data-consent' key={`field-${index}`}>
          <div>
            <CardTitle className='mb-2'>{field.name}</CardTitle>
          </div>

          {!showMedicalDetails ? (
            <div className='mb-3'>
              <p className='ts-fs-14 mb-2'>
                I consent to taking part in the {info.name} activity and understand my responsibilities...
                <span
                  className='ts-color-primary ml-1'
                  style={{ cursor: 'pointer' }}
                  onClick={() => setShowMedicalDetails(true)}
                >
                  read more
                </span>
              </p>
            </div>
          ) : (
            <div className='mb-3'>
              <ul>
                <li>I consent to taking part in the {info.name} activity.</li>
                <li>
                  I confirm that I am aware of {info.name} Code of Conduct and understand and agree to my
                  responsibilities in connection with these policies.
                </li>
                <li>
                  I confirm that to the best of my knowledge, I am physically fit and healthy to play {sportName} and I
                  have completed the medical information as best as possible.
                </li>
                <li>
                  I consent to receiving emergency medical treatment or first aid, which is considered necessary by a
                  qualified medic or first aider.
                </li>
                <li>
                  In the event of an injury, I agree to my medical data being stored confidentially in {info.name}{' '}
                  medical notes system.
                </li>
                <li>I confirm that to the best of my knowledge, all information provided on this form is accurate.</li>
                <li>
                  I understand that consents last for one year and I will advise{' '}
                  <a href={`mailto:${info.email}`} className='ts-color-primary'>
                    {info.email}
                  </a>{' '}
                  of any changes to the information on this form.
                </li>
              </ul>
              <span
                className='ts-color-primary ml-1'
                style={{ cursor: 'pointer' }}
                onClick={() => setShowMedicalDetails(false)}
              >
                read less
              </span>
            </div>
          )}

          <div>Do you agree to all the consents detailed above?</div>

          <Form.Item
            className='mt-3'
            name={getFieldName('competition_consent')}
            rules={[{ required: field.required, message: 'Please select your consent' }]}
          >
            <Radio.Group>
              <Radio value={1}>Yes, I consent</Radio>
              <Radio value={0}>No, I don't consent</Radio>
            </Radio.Group>
          </Form.Item>
        </div>
      );
    }

    // Map field names to their corresponding form field names
    const fieldNameMap: Record<string, string> = {
      'Player First Name': 'firstName',
      'Player Last Name': 'lastName',
      'Player Gender': 'gender',
      'Player Date of Birth': 'dob',
      'Membership Number': 'membershipNumber',
      Ethnicity: 'ethnicity',
      'Home Postcode': 'homePostcode',
      'Kit Size': 'jerseySize',
      'Kit Number': 'jerseyNumber',
      'Player Email Address': 'playerEmail',
      'Parent/Guardian Email Address': 'parent1Email',
      'Emergency Contact First Name': 'emergencyFirstName1',
      'Emergency Contact Last Name': 'emergencyLastName1',
      'Emergency Contact Mobile Number': 'emergencyMobileNumber1',
      Relationship: 'relationship1',
      County: 'county',
      Region: 'region',
      Disability: 'disabilities',
      'Parent/Guardian First Name': 'parent_first_name',
      'Parent/Guardian Last Name': 'parent_last_name',
      'Parent/Guardian Mobile Number': 'parent_mobile_number',
      'Any existing medical condition?': 'medicalDetails',
      'Allow Emergency Treatment': 'emergency_treatment_consent',
      'Current School': 'current_school',
      'Current Club': 'current_club',
      'Current Club Coach Name': 'current_club_coach_name',
      'Current Club Coach Email': 'current_club_coach_email',
      Height: 'height',
      'X Handle': 'x_handle',
      'Instagram Handle': 'instagram_handle',
      'Tiktok Handle': 'tiktok_handle',
      'Family sudden death under 50': 'family_death',
      'Dietary Requirements': 'dietary_requirement',
      'Food or Medication Allergies': 'allergies',
      'Regular Medication': 'medication',
      'Competition Consent': 'competition_consent',
    };

    const fieldName = fieldNameMap[field.name];
    if (!fieldName) return null;

    switch (field.name) {
      case 'Player Gender':
        return (
          <Form.Item
            key={`field-${index}`}
            label={field.name}
            name={getFieldName(fieldName)}
            rules={[
              { required: field.required, message: `Please select the ${field.name.toLowerCase()}` },
              () => ({
                validator(_, value) {
                  if (!field.required) return Promise.resolve();

                  const isGenderMatch =
                    (value === 'male' && ['men', 'boys'].includes(category as string)) ||
                    (value === 'female' && ['girls', 'women'].includes(category as string));

                  if (isGenderMatch || category === 'mixed' || !category) {
                    return Promise.resolve();
                  }

                  return Promise.reject(new Error(`This competition is for ${category} only`));
                },
              }),
            ]}
          >
            <Radio.Group>
              <Radio value='male'>Male</Radio>
              <Radio value='female'>Female</Radio>
            </Radio.Group>
          </Form.Item>
        );

      case 'Player Date of Birth':
        return (
          <Form.Item
            key={`field-${index}`}
            label={field.name}
            name={getFieldName(fieldName)}
            rules={[
              { required: field.required, message: `Please select the ${field.name.toLowerCase()}` },
              () => ({
                validator(_, value) {
                  if (!field.required || !(dobRange.oldestDob && dobRange.youngestDob)) {
                    return Promise.resolve();
                  }
                  const dateOfBirth = new Date(value);
                  const oldestDob = new Date(dobRange.oldestDob);
                  const youngestDob = new Date(dobRange.youngestDob);
                  if (dateOfBirth > oldestDob && dateOfBirth < youngestDob) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('DOB does not fall within the age limit for this competition'));
                },
              }),
            ]}
          >
            <DatePicker style={{ width: '100%' }} onChange={handleDateOfBirthChange} />
          </Form.Item>
        );

      case 'Ethnicity':
        return (
          <Form.Item
            key={`field-${index}`}
            label={field.name}
            name={getFieldName(fieldName)}
            rules={[{ required: field.required, message: `Please select your ${field.name.toLowerCase()}` }]}
          >
            <Select>
              {ETHNICITY_OPTIONS.map(option => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        );

      case 'Kit Size':
        return (
          <Form.Item
            key={`field-${index}`}
            label={field.name}
            name={getFieldName(fieldName)}
            rules={[{ required: field.required, message: `Please select your ${field.name.toLowerCase()}` }]}
          >
            <Select>
              <Option value='6-7 Years old'>6-7 Years old</Option>
              <Option value='7-8 Years old'>7-8 Years old</Option>
              <Option value='9-10 Years old'>9-10 Years old</Option>
              <Option value='11-12 Years old'>11-12 Years old</Option>
              <Option value='12-13 Years old'>12-13 Years old</Option>
              <Option value='13-14 Years old'>13-14 Years old</Option>
              <Option value='Small Adult'>Small Adult</Option>
              <Option value='Medium Adult'>Medium Adult</Option>
              <Option value='Large Adult'>Large Adult</Option>
              <Option value='XLarge Adult'>XLarge Adult</Option>
              <Option value='XXLarge Adult'>XXLarge Adult</Option>
            </Select>
          </Form.Item>
        );

      case 'Relationship':
        return (
          <Form.Item
            key={`field-${index}`}
            label={field.name}
            name={getFieldName(fieldName)}
            rules={[{ required: field.required, message: `Please select your ${field.name.toLowerCase()}` }]}
          >
            <Select>
              {RELATIONSHIP_OPTIONS.map(option => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        );

      case 'County':
        return (
          <Form.Item
            key={`field-${index}`}
            label={field.name}
            name={getFieldName(fieldName)}
            rules={[{ required: field.required, message: `Please select your ${field.name.toLowerCase()}` }]}
          >
            <Select>
              {COUNTY_OPTIONS.map(county => (
                <Option key={county} value={county}>
                  {county}
                </Option>
              ))}
            </Select>
          </Form.Item>
        );

      case 'Region':
        return (
          <Form.Item
            key={`field-${index}`}
            label={field.name}
            name={getFieldName(fieldName)}
            rules={[{ required: field.required, message: `Please select your ${field.name.toLowerCase()}` }]}
          >
            <Select>
              {REGION_OPTIONS.map(region => (
                <Option key={region.value} value={region.value}>
                  {region.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        );

      case 'Disability':
        return (
          <Form.Item
            key={`field-${index}`}
            label={field.name}
            name={getFieldName(fieldName)}
            rules={[{ required: field.required, message: `Please select your ${field.name.toLowerCase()}` }]}
          >
            <Select>
              {DISABILITY_OPTIONS.map(disability => (
                <Option key={disability} value={disability}>
                  {disability}
                </Option>
              ))}
            </Select>
          </Form.Item>
        );

      case 'Player Email Address':
        // if (isOver18 === false) return null;

        return (
          <Form.Item
            key={`field-${index}`}
            label={field.name}
            name={getFieldName(fieldName)}
            rules={[
              { required: field.required, message: `Please enter ${field.name.toLowerCase()}` },
              { type: 'email', message: 'Please enter a valid email address' },
            ]}
          >
            <Input type='email' />
          </Form.Item>
        );

      case 'Parent/Guardian Email Address':
        // if (isOver18 === true) return null;

        return (
          <Form.Item
            key={`field-${index}`}
            label={field.name}
            name={getFieldName(fieldName)}
            rules={[
              { required: field.required, message: `Please enter ${field.name.toLowerCase()}` },
              { type: 'email', message: 'Please enter a valid email address' },
            ]}
          >
            <Input type='email' />
          </Form.Item>
        );

      // If it's a medical condition toggle + text field
      case 'Any existing medical condition?':
        return (
          <React.Fragment key={`field-${index}`}>
            <Form.Item
              label={field.name}
              name={getFieldName(`${fieldName}_toggle`)}
              rules={[{ required: field.required, message: `Please indicate if you have any medical conditions` }]}
            >
              <Radio.Group
                onChange={e => {
                  // The value itself will be set by Ant Design's form
                  // Just need to force re-render for the nested form
                  if (Array.isArray(memoizedFieldNamePrefix)) {
                    // For nested fields, we need to explicitly set the value
                    const nestedFieldPath = getFieldName(`${fieldName}_toggle`);
                    form.setFieldValue(nestedFieldPath, e.target.value);
                  }

                  // Force form to validate and update
                  form.validateFields([getFieldName(`${fieldName}_toggle`)]);
                }}
              >
                <Radio value='yes'>Yes</Radio>
                <Radio value='no'>No</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item noStyle shouldUpdate={() => true}>
              {() => {
                const fieldPath = getFieldName(`${fieldName}_toggle`);
                const stringValue = form.getFieldValue(fieldPath);
                const showDetails = stringValue === 'yes';

                return showDetails ? (
                  <Form.Item
                    label='Please list any medical/health/injury details you have'
                    name={getFieldName(fieldName)}
                    rules={[{ required: true, message: 'Please provide details of your medical condition' }]}
                  >
                    <Input.TextArea rows={4} />
                  </Form.Item>
                ) : null;
              }}
            </Form.Item>
          </React.Fragment>
        );

      // For emergency treatment consent toggle
      case 'Allow Emergency Treatment':
        return (
          <Form.Item
            key={`field-${index}`}
            label={field.name}
            name={getFieldName(fieldName)}
            rules={[{ required: field.required, message: `Please indicate if you allow emergency treatment` }]}
          >
            <Radio.Group>
              <Radio value={1}>Yes</Radio>
              <Radio value={0}>No</Radio>
            </Radio.Group>
          </Form.Item>
        );

      // For parent/guardian fields
      case 'Parent/Guardian First Name':
      case 'Parent/Guardian Last Name':
      case 'Parent/Guardian Mobile Number':
        return (
          <Form.Item
            key={`field-${index}`}
            label={field.name}
            name={getFieldName(fieldName)}
            rules={[{ required: field.required, message: `Please enter ${field.name.toLowerCase()}` }]}
          >
            <Input />
          </Form.Item>
        );

      // New field cases
      case 'Family sudden death under 50':
        return (
          <Form.Item
            key={`field-${index}`}
            label={field.name}
            name={getFieldName(fieldName)}
            rules={[
              {
                required: field.required,
                message: `Please indicate if there's a family history of sudden death under 50`,
              },
            ]}
          >
            <Radio.Group>
              <Radio value={1}>Yes</Radio>
              <Radio value={0}>No</Radio>
            </Radio.Group>
          </Form.Item>
        );

      case 'Current Club Coach Email':
        return (
          <Form.Item
            key={`field-${index}`}
            label={field.name}
            name={getFieldName(fieldName)}
            rules={[
              { required: field.required, message: `Please enter ${field.name.toLowerCase()}` },
              { type: 'email', message: 'Please enter a valid email address' },
            ]}
          >
            <Input type='email' />
          </Form.Item>
        );

      case 'Dietary Requirements':
      case 'Food or Medication Allergies':
      case 'Regular Medication':
        return (
          <Form.Item
            key={`field-${index}`}
            label={field.name}
            name={getFieldName(fieldName)}
            rules={[{ required: field.required, message: `Please enter ${field.name.toLowerCase()}` }]}
          >
            <Input.TextArea rows={4} />
          </Form.Item>
        );

      case 'Height':
        return (
          <Form.Item
            key={`field-${index}`}
            label={field.name}
            name={getFieldName(fieldName)}
            rules={[{ required: field.required, message: `Please select your height` }]}
          >
            <Select>
              {HEIGHT_OPTIONS.map(option => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        );

      // Default case incase we don't have a specific case for the field
      default:
        return (
          <Form.Item
            key={`field-${index}`}
            label={field.name}
            name={getFieldName(fieldName)}
            rules={[{ required: field.required, message: `Please enter ${field.name.toLowerCase()}` }]}
          >
            <Input />
          </Form.Item>
        );
    }
  };

  const renderedFields = formTemplate.map((field, index) => renderField(field, index));

  // Check if both parent/guardian fields and emergency contact fields exist in the form template
  const hasParentFields = formTemplate.some(
    field =>
      ['Parent/Guardian First Name', 'Parent/Guardian Last Name', 'Parent/Guardian Mobile Number'].includes(
        field.name,
      ) && field.use,
  );

  const hasEmergencyFields = formTemplate.some(
    field =>
      ['Emergency Contact First Name', 'Emergency Contact Last Name', 'Emergency Contact Mobile Number'].includes(
        field.name,
      ) && field.use,
  );

  const emergencyFirstNameIndex = formTemplate.findIndex(
    field => field.name === 'Emergency Contact First Name' && field.use,
  );

  // Insert the checkbox before the first emergency contact field if both parent and emergency fields exist
  if (emergencyFirstNameIndex !== -1 && hasParentFields && hasEmergencyFields) {
    renderedFields.splice(
      emergencyFirstNameIndex,
      0,
      <Form.Item key='useParentAsEmergency' className='mb-1'>
        <Checkbox onChange={copyParentToEmergency} checked={useParentAsEmergency}>
          Use parent/guardian details as emergency contact
        </Checkbox>
      </Form.Item>,
    );
  }

  return <>{renderedFields}</>;
};

export default PlayerFormFields;
