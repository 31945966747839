import { useQuery } from 'react-query';
import { apiClient } from 'src/utils/apiClient';

interface CoachProfile {
  user_id: number;
  email: string;
  profile_id: number;
  profile_first_name: string;
  profile_last_name: string;
  profile_role: string;
  profile_avatar: string | null;
}

interface TeamCoach {
  head_coach: CoachProfile[];
  coaches: Array<CoachProfile[] | string>;
  team_venues: {
    venue_full_address: string;
  }[];
}

type TeamCoachesResponse = TeamCoach[];

const getTeamCoaches = async (teamId: number, siteCode?: string) => {
  return await apiClient<TeamCoachesResponse>(`/v1/website/teams/${teamId}/coaches`, { siteCode });
};

export const useGetTeamCoachesQuery = (teamId: number, siteCode?: string) => {
  const result = useQuery(`team-coaches-${teamId}`, () => getTeamCoaches(teamId, siteCode), {
    enabled: !!teamId && !!siteCode,
  });

  return {
    coaches: result.data?.[0]?.coaches
      ?.flatMap(item => (Array.isArray(item) && item.length > 0 ? item : []))
      .filter((coach, index, self) => index === self.findIndex(c => c.user_id === coach.user_id)),
    // headCoaches: result.data?.[0]?.head_coach,
    teamVenue: result.data?.[0]?.team_venues[0]?.venue_full_address,
  };
};
