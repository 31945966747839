import React, { useState } from 'react';
import { Form, Input, Radio, DatePicker, Select, Checkbox } from 'antd';
import { CardTitle } from 'src/shared/widgets/Card';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { HEIGHT_OPTIONS, ETHNICITY_OPTIONS, RELATIONSHIP_OPTIONS } from './PlayerFormFields';

const { Option } = Select;

// Import constants and types from the main PlayerFormFields component
interface DefaultPlayerFormFieldsProps {
  getFieldName: (name: string) => string | (string | number)[];
  dobRange: {
    oldestDob: string | null | undefined;
    youngestDob: string | null | undefined;
  };
  category: string | undefined;
  isOver18: boolean | null;
  handleDateOfBirthChange: (_dateOfBirth: Date, dateString: string | string[]) => void;
  COUNTY_OPTIONS: string[];
  REGION_OPTIONS: Array<{ value: string; label: string }>;
  DISABILITY_OPTIONS: string[];
  useParentAsEmergency: boolean;
  copyParentToEmergency: (e: CheckboxChangeEvent) => void;
  clubName: string;
  sportName: string;
  email: string;
}

const DefaultPlayerFormFields: React.FC<DefaultPlayerFormFieldsProps> = ({
  getFieldName,
  dobRange,
  category,
  handleDateOfBirthChange,
  isOver18,
  COUNTY_OPTIONS,
  REGION_OPTIONS,
  DISABILITY_OPTIONS,
  useParentAsEmergency,
  copyParentToEmergency,
  clubName,
  sportName,
  email,
}) => {
  const [showPhotographyDetails, setShowPhotographyDetails] = useState(false);
  const [showMedicalDetails, setShowMedicalDetails] = useState(false);

  return (
    <>
      <Form.Item
        label='Player First Name'
        name={getFieldName('firstName')}
        rules={[{ required: true, message: 'Please enter the player first name' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label='Player Last Name'
        name={getFieldName('lastName')}
        rules={[{ required: true, message: 'Please enter the player last name' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label='Player Gender'
        name={getFieldName('gender')}
        rules={[
          { required: true },
          () => ({
            validator(_, value) {
              const isGenderMatch =
                (value === 'male' && ['men', 'boys'].includes(category as string)) ||
                (value === 'female' && ['girls', 'women'].includes(category as string));

              if (isGenderMatch || category === 'mixed' || !category) {
                return Promise.resolve();
              }

              return Promise.reject(new Error(`This competition is for ${category} only`));
            },
          }),
        ]}
      >
        <Radio.Group>
          <Radio value='male'>Male</Radio>
          <Radio value='female'>Female</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item
        label='Player Date of Birth'
        name={getFieldName('dob')}
        rules={[
          { required: true, message: 'Please select your date of birth' },
          () => ({
            validator(_, value) {
              if (!(dobRange.oldestDob && dobRange.youngestDob)) {
                return Promise.resolve();
              }
              const dateOfBirth = new Date(value);
              const oldestDob = new Date(dobRange.oldestDob);
              const youngestDob = new Date(dobRange.youngestDob);
              if (dateOfBirth > oldestDob && dateOfBirth < youngestDob) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('DOB does not fall within the age limit for this competition'));
            },
          }),
        ]}
      >
        <DatePicker style={{ width: '100%' }} onChange={handleDateOfBirthChange} />
      </Form.Item>

      <Form.Item
        label='Membership Number'
        name={getFieldName('membershipNumber')}
        rules={[{ required: true, message: 'Please enter your membership number' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label='Ethnicity'
        name={getFieldName('ethnicity')}
        rules={[{ required: true, message: 'Please select your ethnicity' }]}
      >
        <Select>
          {ETHNICITY_OPTIONS.map(option => (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        label='Disabilities'
        name={getFieldName('disabilities')}
        rules={[{ required: true, message: 'Please select your disabilities status' }]}
      >
        <Select>
          <Option value='Hearing impairment'>Hearing impairment</Option>
          <Option value='Visual impairment'>Visual impairment</Option>
          <Option value='Mental health'>Mental health</Option>
          <Option value='Learning disability'>Learning disability</Option>
          <Option value='Physical disability'>Physical disability</Option>
          <Option value='Do not wish to disclose'>Do not wish to disclose</Option>
          <Option value='Not applicable'>Not applicable</Option>
        </Select>
      </Form.Item>

      <Form.Item
        label='Home Post Code'
        name={getFieldName('homePostcode')}
        rules={[{ required: true, message: 'Please enter your home post code' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label='Kit Size'
        name={getFieldName('jerseySize')}
        rules={[{ required: true, message: 'Please select your kit size' }]}
      >
        <Select>
          <Option value='6-7 Years old'>6-7 Years old</Option>
          <Option value='7-8 Years old'>7-8 Years old</Option>
          <Option value='9-10 Years old'>9-10 Years old</Option>
          <Option value='11-12 Years old'>11-12 Years old</Option>
          <Option value='12-13 Years old'>12-13 Years old</Option>
          <Option value='13-14 Years old'>13-14 Years old</Option>
          <Option value='Small Adult'>Small Adult</Option>
          <Option value='Medium Adult'>Medium Adult</Option>
          <Option value='Large Adult'>Large Adult</Option>
          <Option value='XLarge Adult'>XLarge Adult</Option>
          <Option value='XXLarge Adult'>XXLarge Adult</Option>
        </Select>
      </Form.Item>

      <Form.Item
        label='Kit Number'
        name={getFieldName('jerseyNumber')}
        rules={[{ required: true, message: 'Please enter your kit number' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label='County'
        name={getFieldName('county')}
        rules={[{ required: true, message: 'Please select your county' }]}
      >
        <Select>
          {COUNTY_OPTIONS.map(county => (
            <Option key={county} value={county}>
              {county}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        label='Region'
        name={getFieldName('region')}
        rules={[{ required: true, message: 'Please select your region' }]}
      >
        <Select>
          {REGION_OPTIONS.map(region => (
            <Option key={region.value} value={region.value}>
              {region.label}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        label='Disability'
        name={getFieldName('disabilities')}
        rules={[{ required: true, message: 'Please select your disability' }]}
      >
        <Select>
          {DISABILITY_OPTIONS.map(disability => (
            <Option key={disability} value={disability}>
              {disability}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        label='Player Email Address'
        name='playerEmail'
        rules={[
          { required: isOver18 === true, message: 'Please enter the player email address' },
          { type: 'email', message: 'Please enter a valid email address' },
        ]}
      >
        <Input type='email' />
      </Form.Item>

      {isOver18 === false && (
        <>
          <Form.Item
            label='Parent/Guardian Email Address'
            name={getFieldName('parent1Email')}
            rules={[
              { required: isOver18 === false, message: 'Please enter parent/guardian email address' },
              { type: 'email', message: 'Please enter a valid email address' },
            ]}
          >
            <Input type='email' />
          </Form.Item>
        </>
      )}

      <Form.Item
        label='Parent/Guardian First Name'
        name={getFieldName('parent_first_name')}
        rules={[{ required: true, message: 'Please enter parent/guardian first name' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label='Parent/Guardian Last Name'
        name={getFieldName('parent_last_name')}
        rules={[{ required: true, message: 'Please enter parent/guardian last name' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label='Parent/Guardian Mobile Number'
        name={getFieldName('parent_mobile_number')}
        rules={[{ required: true, message: 'Please enter parent/guardian mobile number' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item>
        <Checkbox onChange={copyParentToEmergency} checked={useParentAsEmergency}>
          Use parent/guardian details as emergency contact
        </Checkbox>
      </Form.Item>

      <Form.Item
        label='Emergency Contact First Name'
        name={getFieldName('emergencyFirstName1')}
        rules={[{ required: true, message: 'Please enter emergency contact first name' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label='Emergency Contact Last Name'
        name={getFieldName('emergencyLastName1')}
        rules={[{ required: true, message: 'Please enter emergency contact last name' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label='Emergency Contact Mobile Number'
        name={getFieldName('emergencyMobileNumber1')}
        rules={[{ required: true, message: 'Please enter emergency contact mobile number' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label='Relationship'
        name={getFieldName('relationship1')}
        rules={[{ required: true, message: 'Please select your relationship' }]}
      >
        <Select>
          {RELATIONSHIP_OPTIONS.map(option => (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        label='Any existing medical condition?'
        name={getFieldName('medicalDetails_toggle')}
        rules={[{ required: true, message: 'Please indicate if you have any medical conditions' }]}
      >
        <Radio.Group>
          <Radio value={true}>Yes</Radio>
          <Radio value={false}>No</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) => {
          const prefix = getFieldName('medicalDetails_toggle');
          const prefixStr = typeof prefix === 'string' ? prefix : prefix.join('.');
          return prevValues?.[prefixStr] !== currentValues?.[prefixStr];
        }}
      >
        {({ getFieldValue }) => {
          const prefix = getFieldName('medicalDetails_toggle');
          const prefixStr = typeof prefix === 'string' ? prefix : prefix.join('.');
          const value = getFieldValue(prefixStr);
          return value === true ? (
            <Form.Item
              label='Please list any medical/health/injury details you have'
              name={getFieldName('medicalDetails')}
              rules={[{ required: true, message: 'Please provide details of your medical condition' }]}
            >
              <Input.TextArea rows={4} />
            </Form.Item>
          ) : null;
        }}
      </Form.Item>

      <Form.Item
        label='Allow Emergency Treatment'
        name={getFieldName('emergency_treatment_consent')}
        rules={[{ required: true, message: 'Please indicate if you allow emergency treatment' }]}
      >
        <Radio.Group>
          <Radio value={true}>Yes</Radio>
          <Radio value={false}>No</Radio>
        </Radio.Group>
      </Form.Item>

      {/* Competition Consent */}
      <div id='medical-data-consent'>
        <div>
          <CardTitle className='mb-2'>Competition Consent</CardTitle>
        </div>

        {!showMedicalDetails ? (
          <div className='mb-3'>
            <p className='ts-fs-14 mb-2'>
              I consent to taking part in the {clubName} activity and understand my responsibilities...
              <span
                className='ts-color-primary ml-1'
                style={{ cursor: 'pointer' }}
                onClick={() => setShowMedicalDetails(true)}
              >
                read more
              </span>
            </p>
          </div>
        ) : (
          <div className='mb-3'>
            <ul>
              <li>I consent to taking part in the {clubName} activity.</li>
              <li>
                I confirm that I am aware of {clubName} Code of Conduct and understand and agree to my responsibilities
                in connection with these policies.
              </li>
              <li>
                I confirm that to the best of my knowledge, I am physically fit and healthy to play {sportName} and I
                have completed the medical information as best as possible.
              </li>
              <li>
                I consent to receiving emergency medical treatment or first aid, which is considered necessary by a
                qualified medic or first aider.
              </li>
              <li>
                In the event of an injury, I agree to my medical data being stored confidentially in {clubName} medical
                notes system.
              </li>
              <li>I confirm that to the best of my knowledge, all information provided on this form is accurate.</li>
              <li>
                I understand that consents last for one year and I will advise{' '}
                <a href={`mailto:${email}`} className='ts-color-primary'>
                  {email}
                </a>{' '}
                of any changes to the information on this form.
              </li>
            </ul>
            <span
              className='ts-color-primary ml-1'
              style={{ cursor: 'pointer' }}
              onClick={() => setShowMedicalDetails(false)}
            >
              read less
            </span>
          </div>
        )}

        <div>Do you agree to all the consents detailed above?</div>

        <Form.Item
          className='mt-3'
          name={getFieldName('competition_consent')}
          rules={[{ required: true, message: 'Please select your consent' }]}
        >
          <Radio.Group>
            <Radio value={1}>Yes, I consent</Radio>
            <Radio value={0}>No, I don't consent</Radio>
          </Radio.Group>
        </Form.Item>
      </div>

      <Form.Item
        label='Current School'
        name={getFieldName('current_school')}
        rules={[{ required: true, message: 'Please enter current school' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label='Current Club'
        name={getFieldName('current_club')}
        rules={[{ required: true, message: 'Please enter current club' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label='Current Club Coach Name'
        name={getFieldName('current_club_coach_name')}
        rules={[{ required: true, message: 'Please enter current club coach name' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label='Current Club Coach Email'
        name={getFieldName('current_club_coach_email')}
        rules={[
          { required: true, message: 'Please enter current club coach email' },
          { type: 'email', message: 'Please enter a valid email address' },
        ]}
      >
        <Input type='email' />
      </Form.Item>

      <Form.Item
        label='Height'
        name={getFieldName('height')}
        rules={[{ required: true, message: 'Please select your height' }]}
      >
        <Select>
          {HEIGHT_OPTIONS.map(option => (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item label='X Handle' name={getFieldName('x_handle')} rules={[{ required: false }]}>
        <Input />
      </Form.Item>

      <Form.Item label='Instagram Handle' name={getFieldName('instagram_handle')} rules={[{ required: false }]}>
        <Input />
      </Form.Item>

      <Form.Item label='Tiktok Handle' name={getFieldName('tiktok_handle')} rules={[{ required: false }]}>
        <Input />
      </Form.Item>

      <Form.Item
        label='Family sudden death under 50'
        name={getFieldName('family_death')}
        rules={[{ required: true, message: "Please indicate if there's a family history of sudden death under 50" }]}
      >
        <Radio.Group>
          <Radio value={1}>Yes</Radio>
          <Radio value={0}>No</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item label='Dietary Requirements' name={getFieldName('dietary_requirement')} rules={[{ required: false }]}>
        <Input.TextArea rows={4} />
      </Form.Item>

      <Form.Item
        label='Food or Medication Allergies'
        name={getFieldName('allergies')}
        rules={[{ required: true, message: 'Please provide food or medication allergies information' }]}
      >
        <Input.TextArea rows={4} />
      </Form.Item>

      <Form.Item label='Regular Medication' name={getFieldName('medication')} rules={[{ required: false }]}>
        <Input.TextArea rows={4} />
      </Form.Item>

      <div id='photography-consent'>
        <div>
          <CardTitle className='mb-2'>Photography, Filming and Live Streaming Parent Consent</CardTitle>
        </div>

        {!showPhotographyDetails ? (
          <div className='mb-3'>
            <p className='ts-fs-14 mb-2'>
              In accordance with our Safeguarding Policy, Photography Guidance and Photography and Video Advice (CPSU)
              we will not permit photographs, video or...
              <span
                className='ts-color-primary ml-1'
                style={{ cursor: 'pointer' }}
                onClick={() => setShowPhotographyDetails(true)}
              >
                read more
              </span>
            </p>
          </div>
        ) : (
          <div className='mb-3'>
            <p className='ts-fs-14 mb-2'>
              In accordance with our Safeguarding Policy, Photography Guidance and Photography and Video Advice (CPSU)
              we will not permit photographs, video or other images to be taken without their consent.
            </p>

            <p className='ts-fs-14 mb-2'>{clubName} will follow the guidance for the use of photographs and videos.</p>

            <p className='ts-fs-14 mb-2'>
              {clubName} will take all steps to ensure these images are used solely for the purposes they are intended.
              If you become aware that these images are being used inappropriately you should inform Basketball England
              immediately.
            </p>

            <ul>
              <li>
                I consent for {clubName} to utilise my first and last name, voice, statements, photograph, image,
                likeness, actions in any live or recorded form (including, but not limited to, any form of photography,
                video, display, web content or other transmission or reproduction), in whole or in part, for
                promotional, training and assessment.
              </li>

              <li>
                I consent for {clubName} to live stream games as part of the sport activities that I am involved in and
                recognise that this may include my voice, image, likeness and actions.
              </li>

              <li>
                I can confirm that I have read, or been made aware of, the organisation's photography and videoing
                policy.
              </li>

              <li>
                I can confirm that I have read, or been made aware of how the organisation will use these images or
                videos in future and how these images or videos will be stored within the organisation.
              </li>

              <li>I understand images may be used by {clubName} outside partners.</li>

              <li>
                I consent to {clubName} and trusted partners to utilise my image in whole or in part for joint
                promotional purposes.
              </li>

              <li>
                I understand that consent lasts for one year, and that if I wish to remove my consent I must contact{' '}
                {clubName} directly.
              </li>
            </ul>
            <span
              className='ts-color-primary ml-1'
              style={{ cursor: 'pointer' }}
              onClick={() => setShowPhotographyDetails(false)}
            >
              read less
            </span>
          </div>
        )}

        <div>Do you agree to all of the photography, filming and live streaming consents detailed above?</div>

        <Form.Item
          className='mt-3'
          name={getFieldName('photographyConsentParent')}
          rules={[{ required: true, message: 'Please select your consent' }]}
        >
          <Radio.Group>
            <Radio value={1}>Yes, I consent</Radio>
            <Radio value={0}>No, I don't consent</Radio>
          </Radio.Group>
        </Form.Item>
      </div>
    </>
  );
};

export default DefaultPlayerFormFields;
